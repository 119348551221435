import React, { createContext, useRef, useEffect } from 'react';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { eventsSelector } from '../redux/selectors';
import { useSelector } from 'react-redux';

export const EventsContext = createContext();

export const EventsProvider = ({ children }) => {
    const events = useSelector((state) => eventsSelector(state));

    const eventsStoreRef = useRef(new ArrayStore({
        data: [],
        key: 'id',
    }));
    const eventsDataSourceRef = useRef(new DataSource({
        store: eventsStoreRef.current,
        sort: 'id'
    }));

    useEffect(() => {
        const store = eventsStoreRef.current;
        store.clear();
        events.forEach(event => {
            store.push([{
                type: 'insert',
                data: { ...event }
            }]);
        });

        eventsDataSourceRef.current.reload();
    }, [events]);

    return (
        <EventsContext.Provider value={{ eventsStore: eventsStoreRef.current, eventsDataSource: eventsDataSourceRef.current }}>
            {children}
        </EventsContext.Provider>
    );
};

