import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { memo } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAPI } from '../../services/actions/actionService';
import { setStatus } from './state/headerSlice';
import CircleIcon from '@mui/icons-material/Circle';
import CachedIcon from '@mui/icons-material/Cached';
import { loadActions } from '../../redux/models/action';
import SettingsPopover from './SettingsPopover';
import { UserButton } from '@clerk/clerk-react';

const Header = ({ currentView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id: goalId } = useParams();
  const gotoMyGoals = () => {
    navigate('/goals');
  };

  useEffect(() => {
    ActionAPI.getAll().then((actions) => {
      if (actions?.detail) {
        localStorage.removeItem('accessToken');
        navigate('/signin');
      }
      dispatch(loadActions(actions));
    });
  }, []);
  const { status } = useSelector((state) => state.header);

  useEffect(() => {
    const handleStatusChange = () => {
      if (navigator.onLine) {
        dispatch(setStatus('All saved'));
        window.location.reload(false);
      } else {
        dispatch(setStatus('No Internet connection'));
      }
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [status]);

  useEffect(() => {
    if (navigator.onLine) {
      dispatch(setStatus('All saved'));
    } else {
      dispatch(setStatus('No Internet connection'));
    }
  }, []);

  const reloadPage = () => {
    window.location.reload(false);
  };

  const renderStatus = () => {
    switch (status) {
      case 'Saving':
        return (
          <>
            <CachedIcon className={styles.ellipse} sx={{ color: '#000' }} />
            <span className={styles['status-text']}>{status}...</span>
          </>
        );

      case 'No Internet connection':
        return (
          <>
            <CircleIcon className={styles.ellipse} sx={{ color: '#C1272D' }} />
            <span className={styles['status-text']}>{status}</span>
            <div className={styles.undoredo}>
              <CachedIcon className={styles.ellipse} sx={{ color: '#000' }} onClick={reloadPage} />
            </div>
          </>
        );
      default:
        return (
          <>
            <CircleIcon className={styles.ellipse} sx={{ color: '#00CD52' }} />
            <span className={styles['status-text']}>{status}</span>
            <div className={styles.undoredo}>
              <img className={styles.undo} src='/images/icons/undo.svg' alt='undo' />
              <img className={styles.redo} src='/images/icons/redo.svg' alt='redo' />
            </div>
          </>
        );
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openSettings = (event) => {
    setAnchorEl(event.currentTarget);
    // You can add further logic here for when the image is clicked.
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Any additional closing logic can go here.
  };

  return (
    <Grid container className={styles.header}>
      <Grid item md={5} className={styles.logo}>
        <UserButton />
        {/* <UserProfile/> */}
        {/* <img src='/images/header/bar.svg' alt='bar' onClick={openSettings} className={styles.hamburger} /> */}
        <SettingsPopover anchorEl={anchorEl} handleClose={handleClose} />
        {/* <img src='/images/header/logo.svg' alt='logo' /> */}
        {goalId ? (
          <Button className={styles.myGoalsBtn} onClick={gotoMyGoals}>
            <img src='/images/header/4dots.svg' /> {'   '}
            {t('my.goals')}
          </Button>
        ) : (
          <></>
        )}
      </Grid>
      {/* <Grid item md={7} className={styles.status}>
        <Button className={styles['status-data']}>{renderStatus()}</Button>
      </Grid> */}
    </Grid>
  );
};

export default memo(Header);
