import { api } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';

export const CalendarAPI = {
  googleAuth: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/api/calendar/googleOAuth2/`,
        method: 'POST',
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  outlookAuth: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/api/calendar/outlookOAuth2/`,
        method: 'POST',
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  disconnect: async function (calendars, cancel = false) {
    try {
      const response = await api.request({
        url: `/api/calendar/disconnect/`,
        method: 'POST',
        data: { calendars },
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  connections: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/api/calendar/connections/`,
        method: 'GET',
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  updateConnection: async function (setting, cancel = false) {
    try {
      const response = await api.request({
        url: `/api/calendar/connections/`,
        method: 'PATCH',
        data: setting,
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
};

const cancelApiObject = defineCancelApiObject(CalendarAPI);
