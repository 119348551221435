import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  ReactFlowProvider,
  useKeyPress,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import edgeTypes from '../../components/edgeTypes';
import useLayout from '../../hooks/plantree/useLayout';
import nodeTypes from '../../components/nodeTypes';
import { uuid, getOffset, createTree } from '../../utils/utils';
import useForceUpdate from '../../hooks/plantree/useForceUpdate';
import { useParams } from 'react-router-dom';
import ContextMenu from '../../components/nodeTypes/contextMenu/ContextMenu';
import { GoalsContext } from '../../contexts/goalsContext';
import DeleteGoalDialog from '../../components/deleteDialogs/DeleteGoalDialog';
import { useNavigate } from 'react-router-dom';
import { MyGoalsAPI } from '../../services/myGoals';
import { PlanTreeAPI } from '../../services/planTree';
import Loader from '../../components/loader/Loader';
import { setStatus } from '../../components/header/state/headerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setQueueItems } from '../../components/queue/state/queueSlice';
import { actionsSelector, eventsSelector } from '../../redux/selectors';
import { setEvents } from '../../redux/models/event';
import { setCurrentGoalColor } from '../../redux/reducers/currentGoalColorSlice';
import { EventsContext } from '../../contexts/eventsContext';

const proOptions = { account: 'paid-pro', hideAttribution: true };
const fitViewOptions = {
  padding: 0.95,
};

function PlanTree() {
  const targetItemRef = useRef();
  const [message, setMessage] = useState('');
  const { eventsStore, eventsDataSource } = useContext(EventsContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [targetItem, setTargetItem] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [panOnDrag, setPanOnDrag] = useState(true);
  const [selectedGoalForMenu, setSelectedGoalForMenu] = useState('');
  const [menuAction, setMenuAction] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoal, setIsLoadingGoal] = useState(false);
  const [recenter, setRecenter] = useState(false);
  const [canvasClicked, setCanvasClicked] = useState(false);
  const actions = useSelector((state) => actionsSelector(state));
  const events = useSelector((state) => eventsSelector(state));

  let { id: goalId } = useParams();
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();
  const forceUpdate = useForceUpdate();
  const backSpacePresses = useKeyPress(['Backspace', 'Delete']);
  const enterPressed = useKeyPress(['Enter']);
  const tabPressed = useKeyPress(['Tab']);
  const arrowPressUp = useKeyPress(['ArrowUp']);
  const arrowPressDown = useKeyPress(['ArrowDown']);
  const arrowPressRight = useKeyPress(['ArrowRight']);
  const arrowPressLeft = useKeyPress(['ArrowLeft']);
  const [childNavigatedFrom, setChildNavigatedFrom] = useState(null);

  const { queueItems } = useSelector((state) => state.queueItems);

  //======> API call got get goals
  useEffect(() => {
    const run = async () => {
      localStorage.removeItem('selectedNodeId');
      setIsLoading(true);
      const goal = await MyGoalsAPI.get(goalId);
      dispatch(setCurrentGoalColor(goal.color));
      const tree = await PlanTreeAPI.get(goalId);
      const { nodes, edges } = createTree(goal, tree);
      setRecenter(true);
      setNodes([...nodes]);
      setEdges([...edges]);
      forceUpdate();
      setTimeout(() => {
        document.getElementById(`context-source${goal.id}`)?.click();
        setIsLoading(false);
        setRecenter(false);
      }, 400);
    };

    run();

    return () => {
      localStorage.removeItem('selectedNodeId');
    };
  }, []);

  const debounce = (func, timeout = 250) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  useEffect(() => {
    const nodes = getNodes();
    const edges = getEdges();
    const selectedNodeId = localStorage.getItem('selectedNodeId');
    if (!selectedNodeId) return;
    if (arrowPressDown) {
      const targets = edges.filter((e) => e.source === selectedNodeId).map(({ target }) => target);
      if (childNavigatedFrom) {
        setChildNavigatedFrom(null);
        const nextNode = childNavigatedFrom;
        nextNode && debounce(() => handleOnClickNode(nextNode.id))();
      }
      else if (targets?.length) {
        const nextEligibleNodes = nodes
          .filter((n) => !!targets.includes(n.id) && n.type === 'workflow')
          .sort((a, b) => a.position.x - b.position.x);
        const nextNode = nextEligibleNodes[0];
        nextNode && debounce(() => handleOnClickNode(nextNode.id))();
      }
    } else if (arrowPressUp) {
      const target = edges.find((e) => e.target === selectedNodeId);
      if (target) {
        const childNavigateFromToBeSet = nodes.filter((n) => n.id === target.target)[0];
        setChildNavigatedFrom(childNavigateFromToBeSet);
        debounce(() => handleOnClickNode(target.source))();
      }
    } else if (arrowPressRight) {
      const parentId = edges.find((e) => e.target === selectedNodeId)?.source;
      if (!parentId) return;
      const targets = edges.filter((e) => e.source === parentId).map(({ target }) => target);
      if (targets?.length) {
        const nextEligibleNodes = nodes
          .filter((n) => !!targets.includes(n.id) && n.type === 'workflow')
          .sort((a, b) => a.position.x - b.position.x);
        const selectedIndex = nextEligibleNodes.findIndex((ne) => ne.id === selectedNodeId);
        const nextNode = nextEligibleNodes[selectedIndex + 1];
        nextNode && debounce(() => handleOnClickNode(nextNode.id))();
      }
    } else if (arrowPressLeft) {
      const parentId = edges.find((e) => e.target === selectedNodeId)?.source;
      if (!parentId) return;
      const targets = edges.filter((e) => e.source === parentId).map(({ target }) => target);
      if (targets?.length) {
        const nextEligibleNodes = nodes
          .filter((n) => !!targets.includes(n.id) && n.type === 'workflow')
          .sort((a, b) => a.position.x - b.position.x);
        const selectedIndex = nextEligibleNodes.findIndex((ne) => ne.id === selectedNodeId);
        const nextNode = nextEligibleNodes[selectedIndex - 1];
        nextNode && debounce(() => handleOnClickNode(nextNode.id))();
      }
    }
  }, [arrowPressUp, arrowPressRight, arrowPressDown, arrowPressLeft]);

  const getDeletableIds = (edges, source, temp = []) => {
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].source === source) {
        temp.push(edges[i].target);
        getDeletableIds(edges, edges[i].target, temp);
      }
    }
    return temp;
  };

  useEffect(() => {
    if (backSpacePresses) {
      const nodes = getNodes();
      const selectedNode = nodes.find(({ selected }) => selected);
      if (selectedNode) {
        // Display all required warnings
        const edges = getEdges();
        const subGoals = edges.filter(
          (edge) => edge.source == selectedNode.id && edge.type === 'workflow',
        );
        if (
          actions.filter((action) => action.parent_goal === selectedNode.id).length === 0 &&
          subGoals.length === 0
        ) {
          setSelectedGoalForMenu(selectedNode.id);
          deleteGoal(selectedNode.id);
        } else {
          setSelectedGoalForMenu(selectedNode.id);
          setOpenDeleteModal(true);
        }
      }
    }
  }, [backSpacePresses]);

  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);
  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout(recenter, setRecenter);

  const handleNodeCreation = (id) => {
    setRecenter(true);
    const parentNode = getNode(id);
    if (!parentNode) {
      return;
    }
    //setIsLoadingGoal(true);

    const tempSubGoalId = uuid();

    // create a unique id for the child node
    const childNodeId = tempSubGoalId;
    const goalId = tempSubGoalId;

    // create a unique id for the placeholder (the placeholder gets added to the new child node)
    const childPlaceholderId = uuid();
    const childTabPlaceholderId = uuid();

    // create the child node
    const childNode = {
      id: childNodeId,
      // we try to place the child node close to the calculated position from the layout algorithm
      // 150 pixels below the parent node, this spacing can be adjusted in the useLayout hook
      position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
      type: 'workflow',
      data: {
        label: 'New subGoal',
        goalId,
        actions: [],
        eventsDataSource,
        eventsStore,
      },
      selected: true,
    };

    // we need to create a connection from parent to child
    const childEdge = {
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: 'workflow',
    };

    // create a placeholder for the new child node
    // we want to display a placeholder for all workflow nodes that do not have a child already
    // as the newly created node will not have a child, it gets this placeholder
    const childPlaceholderNode = {
      id: childPlaceholderId,
      // we place the placeholder 150 pixels below the child node, spacing can be adjusted in the useLayout hook
      position: { x: childNode.position.x, y: childNode.position.y + 150 },
      type: 'enterAction',
      data: { label: '+' },
    };

    // we need to create a connection from child to our placeholder
    const childPlaceholderEdge = {
      id: `${childNodeId}=>${childPlaceholderId}`,
      source: childNodeId,
      target: childPlaceholderId,
      type: 'enterAction',
    };

    const childTabPlaceholderNode = {
      id: childTabPlaceholderId,
      position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
      type: 'tabAction',
      data: { label: '+' },
    };

    // we need to create a connection from child to our placeholder
    const childTabPlaceholderEdge = {
      id: `${parentNode.id}=>${childTabPlaceholderId}`,
      source: parentNode.id,
      target: childTabPlaceholderId,
      type: 'tabAction',
    };

    // if the clicked node has had any placeholders as children, we remove them because it will get a child now
    const existingPlaceholders = getNodes()
      .filter(
        (node) =>
          node.type === 'placeholder' || node.type === 'tabAction' || node.type === 'enterAction',
      )
      .map((node) => node.id);

    // add the new nodes (child and placeholder), filter out the existing placeholder nodes of the clicked node
    const unodes = getNodes()
      .map((nd) => {
        nd.selected = false;
        return nd;
      })
      .filter((node) => !existingPlaceholders.includes(node.id))
      .concat([childNode, childTabPlaceholderNode, childPlaceholderNode]);

    setNodes([...unodes]);

    // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
    setEdges((edges) =>
      edges
        .filter((edge) => !existingPlaceholders.includes(edge.target))
        .concat([childEdge, childTabPlaceholderEdge, childPlaceholderEdge]),
    );

    //set selected node id
    localStorage.setItem('selectedNodeId', childNodeId);
    setSelectedGoalForMenu(goalId);
    setTimeout(() => { setMenuAction('rename') }, 100)
    closeContextMenu(true);
    setIsLoadingGoal(false);

    dispatch(setStatus('Saving'));
    PlanTreeAPI.createSubGoal({
      id: tempSubGoalId,
      name: 'New subGoal',
      parent_goal: id,
    }).then(() => {
      dispatch(setStatus('All saved'));
    });
  };

  const createNewNode = () => {
    const selectedNodeId = localStorage.getItem('selectedNodeId');
    if (selectedNodeId) {
      handleNodeCreation(selectedNodeId);
    }
  };

  const createSibling = () => {
    const selectedNodeId = localStorage.getItem('selectedNodeId');
    if (selectedNodeId) {
      const edges = getEdges();
      const targetEdge = edges.find(({ target }) => selectedNodeId === target);
      if (targetEdge) {
        handleNodeCreation(targetEdge.source);
      }
    }
  };

  useEffect(() => {
    if (enterPressed) {
      //const selectedNode = nodes.find(({ selected }) => selected);
      const selectedNodeId = localStorage.getItem('selectedNodeId');
      if (selectedNodeId) {
        handleNodeCreation(selectedNodeId);
      }
    }
  }, [enterPressed]);

  useEffect(() => {
    if (tabPressed) {
      //const selectedNode = nodes.find(({ selected }) => selected);
      const selectedNodeId = localStorage.getItem('selectedNodeId');
      if (selectedNodeId) {
        const edges = getEdges();
        const targetEdge = edges.find(({ target }) => selectedNodeId === target);
        if (targetEdge) {
          handleNodeCreation(targetEdge.source);
        }
      }
    }
  }, [tabPressed]);

  const createPlaceholders = (id) => {
    const currentNode = getNode(id);
    if (!currentNode) {
      return;
    }

    // create a unique id for the placeholder (the placeholder gets added to the new child node)
    const childPlaceholderId = uuid();
    const childTabPlaceholderId = uuid();

    const childPlaceholderNode = {
      id: childPlaceholderId,
      // we place the placeholder 150 pixels below the child node, spacing can be adjusted in the useLayout hook
      position: { x: currentNode.position.x, y: currentNode.position.y + 150 },
      type: 'enterAction',
      data: { label: '+' },
    };

    // we need to create a connection from child to our placeholder
    const childPlaceholderEdge = {
      id: `${currentNode.id}=>${childPlaceholderId}`,
      source: currentNode.id,
      target: childPlaceholderId,
      type: 'enterAction',
    };

    const nodesToAdd = [childPlaceholderNode];
    const edgesToAdd = [childPlaceholderEdge];

    //calculate scrrent node's parent
    const _edges = getEdges();
    const parentNodeEdge = _edges.find((ed) => ed.target === id);
    let childTabPlaceholderNode, childTabPlaceholderEdge;
    if (parentNodeEdge && parentNodeEdge?.source) {
      const parentNode = getNode(parentNodeEdge?.source);
      childTabPlaceholderNode = {
        id: childTabPlaceholderId,
        position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
        type: 'tabAction',
        data: { label: '+' },
      };

      // we need to create a connection from child to our placeholder
      childTabPlaceholderEdge = {
        id: `${parentNode.id}=>${childTabPlaceholderId}`,
        source: parentNode.id,
        target: childTabPlaceholderId,
        type: 'tabAction',
      };

      nodesToAdd.push(childTabPlaceholderNode);
      edgesToAdd.push(childTabPlaceholderEdge);
    }

    // if the clicked node has had any placeholders as children, we remove them because it will get a child now
    const existingPlaceholders = getNodes()
      .filter(
        (node) =>
          node.type === 'placeholder' || node.type === 'tabAction' || node.type === 'enterAction',
      )
      .map((node) => node.id);

    // add the new nodes (child and placeholder), filter out the existing placeholder nodes of the clicked node
    setNodes((nodes) =>
      nodes
        .map((element) => {
          if (element.id === id) {
            element.selected = true;
          } else {
            element.selected = false;
          }
          return element;
        })
        .filter((node) => !existingPlaceholders.includes(node.id))
        .concat([...nodesToAdd]),
    );

    // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
    setEdges((edges) =>
      edges.filter((edge) => !existingPlaceholders.includes(edge.target)).concat([...edgesToAdd]),
    );
    forceUpdate();
  };

  const handleOnClickNode = (id) => {
    const selectedNodeId = localStorage.getItem('selectedNodeId');
    if (selectedNodeId !== id) {
      localStorage.setItem('selectedNodeId', id);
      createPlaceholders(id);
    }
  };

  const handleOnBlur = () => {
    setCanvasClicked(true);
    localStorage.removeItem('selectedNodeId');
    // if the clicked node has had any placeholders as children, we remove them because it will get a child now
    const existingPlaceholders = getNodes()
      .filter(
        (node) =>
          node.type === 'placeholder' || node.type === 'tabAction' || node.type === 'enterAction',
      )
      .map((node) => node.id);

    setNodes((nodes) => nodes.filter((node) => !existingPlaceholders.includes(node.id)));

    // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
    setEdges((edges) => edges.filter((edge) => !existingPlaceholders.includes(edge.target)));
    closeContextMenu();
  };

  const closeContextMenu = (noClear) => {
    const cm = document.getElementById('context-menu');
    cm.style.visibility = 'hidden';
    !noClear && setSelectedGoalForMenu('');
  };

  const openContextMenu = (goalId) => {
    const cm = document.getElementById('context-menu');
    const element = document.getElementById('context-source' + goalId);
    const offset = getOffset(element);
    if (offset) {
      const left = offset?.left + offset?.width;
      const top = offset?.top + offset?.height / 2 + 10;
      const cm = document.getElementById('context-menu');
      if (cm) {
        cm.style.left = '' + left + 'px';
        cm.style.top = '' + top + 'px';
      }
    }
    cm.style.visibility = 'visible';
    setSelectedGoalForMenu(goalId);
    setMenuAction('');
  };

  const clickContextMenu = (action, fromAction) => {
    if (fromAction) {
      return;
    } else {
      setMenuAction(action);
      closeContextMenu(true);
      if (action === 'delete') {
        // Display all required warnings
        const edges = getEdges();
        const subGoals = edges.filter(
          (edge) => edge.source == selectedGoalForMenu && edge.type === 'workflow',
        );
        if (
          actions.filter((action) => action.parent_goal === selectedGoalForMenu).length === 0 &&
          subGoals.length === 0
        ) {
          deleteGoal();
        } else {
          setOpenDeleteModal(true);
        }
      }
    }
  };

  const deleteGoal = (selectedNodeId) => {
    const nodes = getNodes();
    const edges = getEdges();
    const selectedNode = nodes.find(
      ({ data }) => data.goalId === (selectedNodeId || selectedGoalForMenu),
    );
    let unodes = nodes.filter((n) => selectedNode.id !== n.id);
    let uedges = edges.filter((e) => e.target !== selectedNode.id);
    // calculate children
    const deletableIds = getDeletableIds(uedges, selectedNode.id);
    let actions_to_delete = actions
      .filter(
        (action) =>
          deletableIds.includes(action.parent_goal) || selectedNode.id === action.parent_goal,
      )
      .map((action) => action.id);

    let prevEvents = [...events];
    let updatedEvents = prevEvents.filter((event) => !actions_to_delete.includes(event.actionId));
    let prevQueue = [...queueItems];
    let updatedQueue = prevQueue.filter((queueItem) => !actions_to_delete.includes(queueItem));

    unodes = unodes.filter((n) => !deletableIds.includes(n.id));
    uedges = uedges.filter((e) => !deletableIds.includes(e.target));
    setNodes(unodes);
    setEdges(uedges);
    dispatch(setEvents(updatedEvents));
    dispatch(setQueueItems(updatedQueue));
    handleOnBlur();
    if (selectedNode.data.mainGoal) {
      MyGoalsAPI.delete(selectedNodeId || selectedGoalForMenu)
        .then(() => {
          navigate(`/`);
        })
        .catch(() => {
          setNodes(nodes);
          setEdges(edges);
          dispatch(setEvents(prevEvents));
          dispatch(setQueueItems(prevQueue));
        });
    } else {
      MyGoalsAPI.deleteSubGoal(selectedNodeId || selectedGoalForMenu).catch(() => {
        setNodes(nodes);
        setEdges(edges);
        dispatch(setEvents(prevEvents));
        dispatch(setQueueItems(prevQueue));
      });
    }
  };

  const dragStart = (e) => {
    const goalId = e.target.getAttribute('data-source');
    goalId && setDragItem(goalId);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    const goalId = e.target.getAttribute('data-target');
    setTargetItem(goalId);
    targetItemRef.current = goalId;
  };

  const drop = (e) => {
    e.preventDefault();
    const edges = getEdges();

    if (dragItem) {
      const nodes = getNodes();
      const targetId = nodes.find(({ data }) => data.goalId === targetItemRef.current)?.id;
      const sourceId = nodes.find(({ data }) => data.goalId === dragItem)?.id;

      const previousEdges = [...edges];


      setEdges((prevEdges) =>
        prevEdges.map(edge => edge.target === sourceId ? { ...edge, source: targetId } : edge)
      )


      try {
        PlanTreeAPI.updateParentGoal(sourceId, targetId);
        localStorage.setItem('selectedNodeId', targetId);
      } catch (error) {
        setMessage("Something went wrong changing the subgoal's position")
        setEdges(previousEdges);
      }
    }

    setDragItem(null)
    setTargetItem(null)
    forceUpdate()
  };


  const dragOver = (e) => {
    e.preventDefault();
    //const goalId = e.target.getAttribute('data-target');
    // setTargetItem(goalId);
  };

  const dragEnd = (e) => {
    e.preventDefault();
    e.target.style.visibility = 'visible';
    setDragItem(null);
    setTargetItem(null);
  };

  const dragLeave = () => {
    setTargetItem(null);
  };

  return (
    <GoalsContext.Provider
      value={{
        eventsStore,
        eventsDataSource,
        onClickNode: handleOnClickNode,
        openContextMenu,
        clickContextMenu,
        selectedGoalForMenu,
        menuAction,
        setMenuAction,
        closeContextMenu,
        openDeleteModal,
        setOpenDeleteModal,
        deleteGoal,
        createNewNode,
        createSibling,
        panOnDrag,
        setPanOnDrag,
        isLoadingGoal,
        canvasClicked,
        setCanvasClicked,
        goalsReorganize: {
          dragStart,
          dragEnter,
          drop,
          dragOver,
          dragEnd,
          dragLeave,
          dragItem,
        },
      }}
    >
      <ReactFlow
        onConnect={onConnect}
        defaultNodes={[]}
        defaultEdges={[]}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={0.2}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        deleteKeyCode={null}
        onPaneClick={handleOnBlur}
        disableKeyboardA11y={true}
        //nodesFocusable={false}
        panOnDrag={panOnDrag}
      >
        <Background color='transparent' style={{ background: 'transparent' }} gap={0} />
        <Loader open={isLoading} background='#E2ECF1' />
      </ReactFlow>
      <DeleteGoalDialog />
      <ContextMenu hidePlanTree={true} hideColorChange={true} />
    </GoalsContext.Provider>

  );
}

function ReactFlowWrapper() {
  return (
    <ReactFlowProvider>
      <PlanTree />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
