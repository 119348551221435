import React, { memo, useState, useEffect, useContext, useRef } from 'react';
import cx from 'classnames';
import styles from './nodeTypes.module.scss';
import { useNavigate } from 'react-router-dom';
import { GoalsContext } from '../../contexts/goalsContext';
import TextField from '@mui/material/TextField';
import { useReactFlow } from 'reactflow';
import { MyGoalsAPI } from '../../services/myGoals';

const GoalNode = ({ id, data }) => {
  const { setNodes, getNodes } = useReactFlow();
  const [label, setLabel] = useState();
  const {
    openContextMenu,
    selectedGoalForMenu,
    menuAction,
    setMenuAction,
    setPanOnDrag,
    goalsSorting: { dragStart, dragEnter, drop, dragOver, dragEnd, dragLeave },
  } = useContext(GoalsContext);

  const goalNameInput = useRef(null);

  const nodeClasses = cx(styles.homeNode, styles.goalNode);
  const navigate = useNavigate();
  const gotoGoalDetails = () => {
    navigate(`/goals/${id}`);
  };

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    if (menuAction === 'rename') {
      setTimeout(() => {
        if (goalNameInput.current) {
          goalNameInput.current.focus();
        }
        document.getElementById('outlined-basic')?.target?.select();
      });
    }
  }, [menuAction]);

  const handleOpenContextMenu = (e) => {
    e.stopPropagation();
    openContextMenu(id);
  };

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const saveLabel = () => {
    const nodes = getNodes();
    nodes.forEach((element) => {
      if (element.id === id) {
        element.data.label = label;
      }
    });
    setNodes([...nodes]);
    setMenuAction('');
    MyGoalsAPI.modify(id, { name: label });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveLabel();
    }
  };

  const handleFocus = (event) => event.target.select();

  const renderLabel = () => {
    const isEditable = menuAction === 'rename' && id == selectedGoalForMenu;
    if (isEditable) {
      return (
        <TextField
          id='outlined-basic'
          value={label}
          variant='outlined'
          onClick={(e) => e.stopPropagation()}
          onChange={handleLabelChange}
          //multiline
          //maxRows={4}
          onKeyDown={handleKeyDown}
          onBlur={saveLabel}
          ref={goalNameInput}
          autoFocus={true}
          onFocus={handleFocus}
        />
      );
    }
    return data.label;
  };

  return (
    <div className={styles.goalNodeMainContainer}>
      <div
        className={styles.leftCursor + ' targetDiv'}
        onDragEnter={dragEnter}
        onDragOver={dragOver}
        onDrop={drop}
        onDragLeave={dragLeave}
        data-target={`left-${id}`}
        id={id}
      ></div>
      <div
        className={styles.goalNodeContainer}
        onClick={gotoGoalDetails}
        id={'context-source' + id}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onDragLeave={dragLeave}
        draggable
        onMouseEnter={() => setPanOnDrag(false)}
        onMouseLeave={() => setPanOnDrag(true)}
        data-source={id}
      >
        <img
          src='/images/icons/more-horiz.svg'
          alt='more'
          onClick={handleOpenContextMenu}
          className={styles.contextIcon}
        />
        <div className={nodeClasses} style={{ background: data.color, border: 'none' }}>
          {data.completed && (
            <img
              src='/images/icons/goal-done.svg'
              alt='Goal Achieved'
              style={{ marginTop: '15px' }}
            />
          )}
        </div>
        <div className={styles.goalLabel}>{renderLabel()}</div>
      </div>
      <div
        className={styles.rightCursor + ' targetDiv'}
        onDragEnter={dragEnter}
        onDragOver={dragOver}
        onDrop={drop}
        onDragLeave={dragLeave}
        data-target={`right-${id}`}
        id={id}
      ></div>
    </div>
  );
};

export default memo(GoalNode);
