import React, { StrictMode, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './i18n';
import './index.css';
import 'devextreme/dist/css/dx.light.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './genericStylesSheet/variables.css';
import './fonts.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClerkProvider } from '@clerk/clerk-react';
import MobileWarning from './components/mobile/mobileScreen';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1080);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return <MobileWarning></MobileWarning>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <ClerkProvider publishableKey={clerkPubKey} appearance={{
          variables: {
            colorPrimary: "#0c2340",
            colorAlphaShaded: "#f1f5f7",
            colorSuccess: "#0c2340",
            colorDanger: "#fe3b1f",
            colorWarning: "#fe3b1f",
            fontFamilyButtons: "Gellix Medium",
            fontFamily: "Gellix Medium",
          }
        }} >
          <StrictMode>
            <BrowserRouter basename="/">
              <Routes />
            </BrowserRouter>
          </StrictMode>
        </ClerkProvider>
      </Provider>
    </LocalizationProvider>
  );
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);

console.warn = () => { };
