import { Model, attr } from 'redux-orm';
import { createAction } from '@reduxjs/toolkit';

class Action extends Model {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      notes: attr(),
      parent_goal: attr(),
      completed: attr(),
      dateCompleted: attr(),
      scheduled: attr(),
      repetitive: attr(),
      color: attr(),
    };
  }

  static reducer(action, Action) {
    switch (action.type) {
      case loadActions.toString():
        action.payload.forEach((action) => Action.upsert(action));
        break;
      case addAction.toString():
        Action.create(action.payload);
        break;
      case updateAction.toString():
        Action.withId(action.payload.id).update(action.payload);
        break;
      case deleteAction.toString():
        Action.withId(action.payload).delete();
        break;
      case toggleAchievedAction.toString(): {
        const actionToUpdate = Action.withId(action.payload);
        actionToUpdate.update({
          completed: !actionToUpdate.completed,
          dateCompleted: new Date().toISOString().slice(0, 10),
        });
        break;
      }
      default:
        break;
    }
  }
}
Action.modelName = 'Action';

export const loadActions = createAction('LOAD_ACTIONS');
export const addAction = createAction('ADD_ACTION');
export const updateAction = createAction('UPDATE_ACTION');
export const deleteAction = createAction('DELETE_ACTION');
export const toggleAchievedAction = createAction('TOOGLE_ACHIEVED_ACTION');

export default Action;
