import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

export default function Loader({ open: isOpen, noBackground = false, background = '#fff' }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return open ? (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
      className={styles.center + ' loaderbg'}
      style={{ background: noBackground ? 'none' : background }}
    >
      {open && <CircularProgress color='success' />}
    </Backdrop>
  ) : (
    <></>
  );
}
