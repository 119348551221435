const { v4: uuidv4 } = require('uuid');

export const uuid = uuidv4; // Generates a UUID

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    height: rect.height,
    width: rect.width,
  };
};

export const formatter = (data) => {
  return data?.map((item) => {
    const { color, completed, id, name } = item;
    const object = {
      id: '' + id,
      type: 'goal',
      data: {
        label: name,
        goalId: '' + id,
        color,
        completed,
      },
      position: { x: 120, y: 100 },
      color: color,
    };
    return object;
  });
};

export const createTree = (goal, tree) => {
  const { name, id, completed, color } = goal;

  const goalData = [
    {
      id: '' + id,
      type: 'workflow',
      data: {
        label: name,
        mainGoal: true,
        goalId: '' + id,
        completed,
        color,
      },
      position: { x: 0, y: 0 },
      selected: true,
      actions: [],
    },
    {
      id: '2',
      position: { x: 0, y: 150 },
      type: 'enterAction',
      data: { label: '+' },
    },
  ];
  const edgeData = [{ id: 'e1-2', source: '' + id, target: '2', type: 'enterAction' }];

  if (tree) {
    const generateNodes = (data) => {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        result.push(formatNode(data[i]));
        if (data[i].subgoals.length) {
          result.push(...generateNodes(data[i].subgoals));
        }
      }
      return result;
    };
    const generateEdges = (data, parent) => {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        result.push(formatEdge(data[i], parent));
        if (data[i].subgoals.length) {
          result.push(...generateEdges(data[i].subgoals, data[i].id));
        }
      }
      return result;
    };
    const nodes = generateNodes(tree);
    const edges = generateEdges(tree, id);
    goalData.push(...nodes);
    edgeData.push(...edges);
  }

  return { nodes: goalData, edges: edgeData };
};

const formatNode = (goal) => {
  const { name, id, completed } = goal;
  return {
    id: '' + id,
    type: 'workflow',
    position: { x: 0, y: 0 },
    data: {
      label: name,
      goalId: '' + id,
      completed,
      color: goal.color,
    },
  };
};

const formatEdge = (goal, parent) => {
  const { id } = goal;
  return { id: `e${parent}-${id}`, source: '' + parent, target: '' + id, type: 'workflow' };
};

export const isItemExist = (items, keyFromObject, value) => {
  return items.some((item) => item[keyFromObject] == value);
};

export const dateCreator = (date, time) => {
  const { $D, $M, $y } = date;
  const [hours, minutes] = time.split(':');
  return new Date($y, $M, $D, hours, minutes);
};

export const actionFormatter = (actions) => {
  const formatAction = {};
  actions.forEach((item) => {
    if (formatAction[item.parent_goal]) {
      formatAction[item.parent_goal] = [...formatAction[item.parent_goal], item];
    } else {
      formatAction[item.parent_goal] = [item];
    }
  });
  return formatAction;
};

export const formatDate = (date) => {
  return date?.toISOString()?.split('.')[0];
};

export const generateSchedulePayload = (startDate, endDate, allDay, repetitive = false) => {
  return { start_dt: formatDate(startDate), end_dt: formatDate(endDate), is_anytime: allDay, repetitive: repetitive };
};

export const sortDataByDates = (data, param1, param2) => {
  data?.sort(function (a, b) {
    return new Date(b[param1]) - new Date(a[param2]);
  });
  return data;
};

export const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const getDefaultStartTime = (offsetHours = 0) => {
  let date = new Date();
  let minutes = date.getMinutes();
  let hours = date.getHours();

  // if minutes are greater than or equal to 30
  if (minutes >= 30) {
    minutes = 0; // reset minutes
    hours++; // increment hour
  } else if (minutes < 30 && minutes >= 15) {
    minutes = 30; // round up if minutes are >= 15
  } else {
    minutes = 0; // round down if minutes are < 15
  }

  hours += offsetHours;

  // adjust hours to 24-hour format
  if (hours >= 24) {
    hours = hours % 24;
  }

  // convert hours and minutes to strings
  let hoursString = hours < 10 ? "0" + hours : hours;
  let minutesString = minutes < 10 ? "0" + minutes : minutes;

  // return the time string in HH:MM format
  return hoursString + ":" + minutesString;
}
