import React, { useCallback, useState, useEffect } from 'react';
import ReactFlow, { addEdge, Background, ReactFlowProvider, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import edgeTypes from '../../components/edgeTypes';
import nodeTypes from '../../components/nodeTypes';
import ContextMenu from '../../components/nodeTypes/contextMenu/ContextMenu';
import { GoalsContext } from '../../contexts/goalsContext';
import { getOffset, uuid, formatter } from '../../utils/utils';
import { AddGoal } from '../../components/addGoal';
import DeleteGoalDialog from '../../components/deleteDialogs/DeleteGoalDialog';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addGoal, setGoals } from './state/goalSlice';
import { getGoals } from './state/goals.selector';
import useForceUpdate from '../../hooks/plantree/useForceUpdate';
import { MyGoalsAPI } from '../../services/myGoals';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';

const proOptions = { account: 'paid-pro', hideAttribution: true };
const fitViewOptions = {
  padding: 0.95,
};

function MyGoals() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedGoalForMenu, setSelectedGoalForMenu] = useState('');
  const [menuAction, setMenuAction] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [panOnDrag, setPanOnDrag] = useState(true);
  const [dragItem, setDragItem] = useState(-1);
  const [targetItem, setTargetItem] = useState([]);
  const [droppedGoalId, setDroppedGoalId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setEdges, setNodes, getNodes, fitView } = useReactFlow();

  //======> API call got get goals
  useEffect(() => {
    setIsLoading(true);
    MyGoalsAPI.getAll().then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('accessToken');
        navigate('/signin');
      }
      // response handling
      let goals = formatter(response.data);
      rearrangeGoals(goals);
      dispatch(setGoals(JSON.parse(JSON.stringify(goals))));
      setIsLoading(false);
    });
  }, []);

  //redux getter
  const myGoals = useSelector(getGoals);

  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), [setEdges]);

  const openContextMenu = (id) => {
    const cm = document.getElementById('context-menu');
    const element = document.getElementById('context-source' + id);
    const offset = getOffset(element);
    if (offset) {
      const left = offset?.left + offset?.width - 20;
      const top = offset?.top + 20;
      const cm = document.getElementById('context-menu');
      if (cm) {
        cm.style.left = '' + left + 'px';
        cm.style.top = '' + top + 'px';
      }
    }
    cm.style.visibility = 'visible';
    setSelectedGoalForMenu(id);
    setMenuAction('');
  };

  const closeContextMenu = (noClear) => {
    const cm = document.getElementById('context-menu');
    cm.style.visibility = 'hidden';
    !noClear && setSelectedGoalForMenu('');
  };

  const clickContextMenu = (action) => {
    setMenuAction(action);
    closeContextMenu(true);
    if (action === 'delete') {
      setOpenDeleteModal(true);
    }
  };

  const makeFitView = () => {
    setTimeout(() => {
      fitView({
        duration: 200,
        minZoom: 1,
        maxZoom: 1,
      });
    });
  };

  const createNewGoal = (isFitView) => {
    const goalId = uuid();
    var randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
    const xInitial = 120;
    const yInitial = 100;
    const xOffset = 300;
    const yOffset = 250;
    const nodes = getNodes();
    const lastNode = nodes[nodes.length - 1];
    if (lastNode && !lastNode.data.label) {
      setIsOpenAlert(true);
      setMessage(t('name.required.message'));
      return;
    }
    const row = Math.ceil(nodes.length / 3);
    let createAt = (nodes.length + 1) % 3;
    createAt = createAt === 0 ? 3 : createAt;
    const nextRow = nodes.length % 3 === 0 ? row + 1 : row;
    const goalData = {
      id: '' + goalId,
      type: 'goal',
      data: {
        label: 'New Goal',
        goalId: '' + goalId,
        color: '#' + randomColor,
        completed: false,
      },
      position: {
        x: xInitial + xOffset * (createAt - 1),
        y: yInitial + yOffset * (nextRow - 1),
      },
    };
    nodes.push(goalData);
    // redux state setting
    dispatch(addGoal(JSON.parse(JSON.stringify(goalData))));
    setNodes([...nodes]);
    forceUpdate();
    setTimeout(() => {
      setSelectedGoalForMenu(goalId);
      setMenuAction('rename');
      isFitView && makeFitView();
    }, 100);
    MyGoalsAPI.create({ name: 'New Goal', color: '#' + randomColor, id: goalId }).then(() => { });
  };

  const deleteGoal = () => {
    MyGoalsAPI.delete(selectedGoalForMenu).then(() => {
      const nodes = getNodes();
      const filteredNodes = nodes.filter((element) => {
        return element.data.goalId !== selectedGoalForMenu;
      });
      rearrangeGoals(filteredNodes, 'noFitView');
      window.location.reload();
    });
  };

  const updateTargets = (goalId) => {
    const targetDivs = document.getElementsByClassName('targetDiv');
    for (let i = 0; i < targetDivs.length; i++) {
      if (goalId && targetDivs[i].id === goalId) continue;
      targetDivs[i].style.zIndex = 1;
    }
  };

  const resetTargets = (goalId) => {
    const targetDivs = document.getElementsByClassName('targetDiv');
    for (let i = 0; i < targetDivs.length; i++) {
      if (goalId && targetDivs[i].id === goalId) continue;
      targetDivs[i].style.zIndex = -1;
      targetDivs[i].style.borderRight = 'none';
      targetDivs[i].style.borderLeft = 'none';
    }
  };

  const dragStart = (e) => {
    setTimeout(function () {
      e.target.style.visibility = 'hidden';
    }, 0);
    const goalId = e.target.getAttribute('data-source');
    goalId && setDragItem(goalId);
    updateTargets(goalId);
  };

  const isNotNeighbor = (dragItem, parts) => {
    const nodes = getNodes();
    const dragIndex = nodes.findIndex((node) => node.data.goalId === dragItem);
    const leftNode = nodes[dragIndex - 1];
    if (leftNode && leftNode?.data?.goalId === parts[1] && parts[0] === 'right') {
      return false;
    }
    const rightNode = nodes[dragIndex + 1];
    if (rightNode && rightNode?.data?.goalId === parts[1] && parts[0] === 'left') {
      return false;
    }
    return true;
  };

  const dragEnter = (e) => {
    e.preventDefault();
    const goalId = e.target.getAttribute('data-target');
    if (goalId) {
      const parts = goalId.split('-');
      if (parts?.length === 2 && parts[1] !== dragItem && isNotNeighbor(dragItem, parts)) {
        e.target.style[parts[0] === 'left' ? 'borderLeft' : 'borderRight'] = '4px solid #e35249';
        setTargetItem(parts);
      }
    }
  };

  const rearrangeGoals = (nodes, noFitView) => {
    const xInitial = 120;
    const xOffset = 300;

    const yInitial = 100;
    const yOffset = 250;

    const updatedNodes = nodes.map((node, index) => {
      const row = Math.ceil((index + 1) / 3);
      let column = index + 1 - (row - 1) * 3;

      const position = {
        x: xInitial + (column - 1) * xOffset,
        y: yInitial + (row <= 0 ? 0 : row - 1) * yOffset,
      };
      node.position = position;
      nodes.positionAbsolute = position;

      return node;
    });
    setNodes([...updatedNodes]);
    !noFitView && makeFitView();
  };

  const drop = (e) => {
    e.preventDefault();
    if (!targetItem || targetItem.length === 0 || !dragItem || dragItem === targetItem) return;
    const copyListItems = [...getNodes()];
    const dragItemContent = copyListItems.find((cl) => dragItem == cl.data.goalId);
    const draggedIndex = copyListItems.findIndex((cl) => dragItem == cl.data.goalId);
    const targetIndex = copyListItems.findIndex((cl) => targetItem[1] == cl.data.goalId);
    let position;
    if (targetItem[0] === 'left') {
      draggedIndex < targetIndex
        ? (position = targetIndex <= 0 ? 0 : targetIndex - 1)
        : (position = targetIndex);
    } else if (targetItem[0] === 'right') {
      draggedIndex < targetIndex ? (position = targetIndex) : (position = targetIndex + 1);
    }
    copyListItems.splice(draggedIndex, 1);
    copyListItems.splice(position, 0, dragItemContent);
    setDragItem(-1);
    setTargetItem([]);
    rearrangeGoals(copyListItems);
    forceUpdate();
  };

  const dragOver = (e) => {
    e.preventDefault();
    const goalId = e.target.getAttribute('data-source');
    setDroppedGoalId(goalId);
  };

  const dragEnd = (e) => {
    e.preventDefault();
    e.target.style.visibility = 'visible';
    resetTargets();
  };

  const dragLeave = (e) => {
    setDroppedGoalId(null);
    e.target.style.borderRight = 'none';
    e.target.style.borderLeft = 'none';
  };

  return (
    <GoalsContext.Provider
      value={{
        openContextMenu,
        clickContextMenu,
        selectedGoalForMenu,
        menuAction,
        setMenuAction,
        closeContextMenu,
        createNewGoal,
        openDeleteModal,
        setOpenDeleteModal,
        deleteGoal,
        panOnDrag,
        setPanOnDrag,
        goalsSorting: {
          dragStart,
          dragEnter,
          drop,
          dragOver,
          dragEnd,
          droppedGoalId,
          dragLeave,
        },
      }}
    >
      <ReactFlow
        onConnect={onConnect}
        defaultNodes={myGoals.goals}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={1}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        deleteKeyCode={null}
        disableKeyboardA11y={true}
        onPaneClick={closeContextMenu}
        panOnDrag={panOnDrag}
      >
        <Background color='#E2ECF1' style={{ background: '#E2ECF1' }} gap={0} />
        <Loader open={isLoading} background='#E2ECF1' />
      </ReactFlow>
      <ContextMenu mainGoal />
      <AddGoal />
      <DeleteGoalDialog />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        message={message}
        key={'top center'}
      />
    </GoalsContext.Provider>
  );
}

function ReactFlowWrapper() {
  return (
    <ReactFlowProvider>
      <MyGoals />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
