import styles from './styles.module.scss';
import React, { memo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const ConfirmationPopup = ({
  isOpen,
  heading,
  subHeading,
  backButtonText = 'Cancel',
  submitButtonText = 'Delete',
  submitAction = () => { },
  backAction = () => { },
  closeOnBackButton = true,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackButtonAction = () => {
    closeOnBackButton && setOpen(false);
    backAction();
  };
  if (!open) return null;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className={styles.title}>
          {heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.subTitle} id='alert-dialog-description'>
            {subHeading}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actionContainer}>
          <Button className={styles.goBack} onClick={handleBackButtonAction}>
            {backButtonText}
          </Button>
          <Button className={styles.delete} onClick={submitAction}>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(ConfirmationPopup);
