const constants = {
  DELETE: 'Delete',
  DELETE_COMPLETELY: 'Delete Completely',
  HEADING1: 'ACTION',
  HEADING2: 'is scheduled in Calendar',
  GO_BACK: 'Go Back',
  UNSCHEDULE_DELETE: 'Unschedule and delete',
  ACTION_HEADING: 'Action ?',
  KEEP_IN_PLAN_TREE: 'Keep in Plan Tree',
  REPETITIVE_ACTION_HEADING: " and all following repeated instances of it?",
  DELETE_ALL_INSTANCES: "Delete All Instances",
};

export { constants };
