import React from 'react';
import Popover from '@mui/material/Popover';
import styles from './styles.module.scss';
import { Divider } from '@mui/material';
import { AuthAPI } from '../../services/auth/authService';
import { useNavigate } from 'react-router-dom';

const MyPopover = ({ anchorEl, handleClose }) => {
    const navigate = useNavigate();

    const handleLogOut = (event) => {
        event.preventDefault();
        AuthAPI.logout(localStorage.getItem('refreshToken')).then((response) => {
            if (response.status == 401) {
                console.log("error")
                // setErrorMessage(response?.data?.detail);
            } else {
                localStorage.removeItem('accessToken');
                navigate('/');
            }
        });
    };

    const handleClickSettingsTemporary = () => {
        window.open('mailto:info@wayy.life?subject=Need%20assistance&body=As%20our%20software%20is%20still%20in%20alpha%2C%20we%20have%20not%20yet%20implemented%20a%20robust%20automated%20settings%20and%20help%20section.%20If%20you%20need%20help%20with%20anything%2C%20have%20found%20a%20bug%2C%20or%20would%20like%20to%20have%20your%20account%20deleted%2C%20please%20let%20us%20know%20through%20this%20email%2C%20and%20we%20promise%20to%20get%20back%20to%20you%20asap%21');
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '.MuiPopover-paper': {
                    borderRadius: "8px",
                }
            }}
        >
            <div className={styles.settingsDropdown}>
                <div className={styles.menuHeading}>Settings</div>

                <div className={styles.menuItem} onClick={handleClickSettingsTemporary}>
                    <img src="/images/header/user.svg" alt="User Icon" />
                    Account
                </div>
                <div className={styles.menuItem} onClick={handleClickSettingsTemporary}>
                    <img src="/images/header/starFilled.svg" alt="Membership Icon" />
                    Membership
                </div>
                <Divider />
                <div className={styles.menuItem} onClick={handleClickSettingsTemporary}>
                    <img src="/images/header/questionMark.svg" alt="Help Icon" />
                    Help
                </div>
                <Divider />
                <div className={styles.menuItem} style={{ color: "#FF4027" }} onClick={handleLogOut}>
                    <img src="/images/header/logout.svg" alt="Logout Icon" />
                    Log Out
                </div>
            </div>
        </Popover>
    );
};

export default MyPopover;

