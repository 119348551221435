import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import QueueList from './QueueList';
import styles from './styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import DeleteQueueDialog from '../deleteDialogs/DeleteQueueDialog';
import { deleteQueueItem, addQueueItem, setQueueItems } from './state/queueSlice';
import { AppContext } from '../../contexts/appContext';
import { getGoals } from '../../pages/myGoals/state/goals.selector';
import { uuid, formatter } from '../../utils/utils';
import { setGoals } from '../../pages/myGoals/state/goalSlice';
import { MyGoalsAPI } from '../../services/myGoals';
import { QueueAPI } from '../../services/queue/queueService';
import AddIcon from '@mui/icons-material/Add';
import { ActionAPI } from '../../services/actions/actionService';
import { setStatus } from '../header/state/headerSlice';
import { addAction } from '../../redux/models/action';
import { deleteAction } from '../../redux/models/action';

const Queue = React.memo(({actions}) => {
  const dispatch = useDispatch();
  const { queueItems } = useSelector((state) => state.queueItems);
  const myGoals = useSelector(getGoals);
  const [deleteActionId, setDeleteActionId] = useState('');
  const [deleteActionName, setDeleteActionName] = useState('');
  const [isNewInput, setIsNewInput] = useState(false);
  const [isoverFlowInput, setIsoverFlowInput] = useState(false);
  const [openOverFlowItem, setOpenOverFlowItem] = useState(false);
  const { drop, dragOver, dragLeave } = useContext(AppContext);

  const maxQueueItemsDisplayed = 30;
  const mainQueueItems = [...queueItems];
  const overflowQueueItems = mainQueueItems?.splice(maxQueueItemsDisplayed).reverse();

  const handleDeleteAction = ({ id, name, parent_goal }) => {
    if (!parent_goal) {
      deleteFromQueue({ id });
    } else {
      setDeleteActionId(id);
      setDeleteActionName(name);
    }
  };

  useEffect(() => {
    if (myGoals.goals?.length === 0) {
      MyGoalsAPI.getAll().then((response) => {
        const goals = formatter(response.data);
        dispatch(setGoals(JSON.parse(JSON.stringify(goals))));
      });
    }
  }, []);

  useEffect(() => {
    if (myGoals.goals?.length === 0) {
      QueueAPI.getAll().then((queue) => {
        dispatch(setQueueItems(queue.map((item) => item.id)));
      });
    }
  }, []);

  const deleteActionCompletely = () => {
    dispatch(deleteQueueItem(deleteActionId));
    dispatch(deleteAction(deleteActionId));
    ActionAPI.delete(deleteActionId);
  };

  const deleteFromQueue = (action) => {
    const actionId = action?.id || deleteActionId;
    const actionIds = { actionIds: [actionId] };
    dispatch(deleteQueueItem(actionId));
    QueueAPI.delete(actionIds);
  };

  const saveQueueitem = (name) => {
    const obj = {
      id: uuid(),
      name: name,
      parent_goal: '',
    };
    dispatch(addAction(obj));
    ActionAPI.create(obj, false, true).then((response) => {
      if (response) {
        dispatch(addQueueItem(response.id));
        dispatch(setStatus('All saved'));
      }
    });
    setIsNewInput(false);
    setIsoverFlowInput(false);
  };

  const addNewQueueItem = (e, isoverflow = false) => {
    e.stopPropagation();
    e.preventDefault();
    if (isoverflow) {
      setIsoverFlowInput(true);
    } else if (overflowQueueItems.length < 1) {
      setIsNewInput(true);
    }
  };

  const openOverFlowQueueItems = (e) => {
    e.stopPropagation();
    setOpenOverFlowItem((prev) => !prev);
  };

  const handleOnBlur = () => {
    // console.log("gee")
  };

  return (
    <>
      <Grid
      
        className={styles.queue}
        onDragOver={dragOver}
        onDrop={(e) => drop(e, 'queue')}
        onDragLeave={dragLeave}
        id={'queue-area'}
        data-end={'grid'}
        // onClick={(e) => addNewQueueItem(e, false)}
      >
        
        <div className={styles.queueArrow}>
          <Typography className={styles.queueArrowText}>Schedule action</Typography>
          <img src='/images/queue/arrow.svg' />
        </div>
        
        <QueueList
        
          mainQueueItems={mainQueueItems}
          overflowQueueItems={overflowQueueItems}
          handleDeleteAction={handleDeleteAction}
          removeFromQueue={deleteFromQueue}
          saveQueueitem={saveQueueitem}
          isNewInput={isNewInput}
          isoverFlowInput={isoverFlowInput}
          addOverflowQueuItem={(e) => addNewQueueItem(e, true)}
          openOverFlowItem={openOverFlowItem}
          handleOnBlur={handleOnBlur}
          actions={actions}
        />
       
        {overflowQueueItems.length > 0 && (
          <div
            style={{
              position: 'absolute',
              minWidth: 50,
              height: 44,
              bottom: 5,
              left: 5,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div className={styles.queueText}>Queue</div>
            <div className={styles.queueItemCount}>
              <AddIcon
                sx={{ color: '#0c2340', fontSize: '16px' }}
                onClick={openOverFlowQueueItems}
              />
              <span>{overflowQueueItems.length}</span>
            </div>
          </div>
        )}
         
      </Grid>
     
      <DeleteQueueDialog
        deleteActionId={deleteActionId}
        actionName={deleteActionName}
        setDeleteActionId={setDeleteActionId}
        deleteAction={deleteActionCompletely}
        deleteFromQueue={deleteFromQueue}
      />
    </>
  );
});

Queue.displayName = 'Queue';

export default Queue;
