import React from 'react';
import Popover from '@mui/material/Popover';
import styles from './contextMenu.module.scss';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircleIcon from '@mui/icons-material/Circle';
import { getGoals } from '../../../pages/myGoals/state/goals.selector';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../redux/models/action';

export const ConnectToGoalPopup = ({ action, handleParentClose }) => {
  const myGoals = useSelector(getGoals);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const connectActionToGoal = (item) => {
    const updatedAction = { ...action, goalId: item.id };
    dispatch(updateAction(updatedAction));
    setAnchorEl(null);
    handleParentClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} className={styles.menu} onClick={handleClick}>
        <div className={styles.menuItem}>
          <span className={styles.menuTextNormal}>Connect to a Goal</span>
          <span className={styles.menuTextGrey}>[G]</span>
        </div>
        <span className={styles.actionMenuIconBlack}>
          <ChevronRightOutlinedIcon />
        </span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ margin: '-12px 0 0 14px' }}
      >
        <Card sx={{ minWidth: 340 }}>
          <CardContent>
            {myGoals?.goals.map((item, index) => {
              return (
                <div className={styles.menu} key={index} onClick={() => connectActionToGoal(item)}>
                  <div className={styles.menuItem}>
                    <span className={styles.menuTextNormal}>{item?.data?.label}</span>
                  </div>
                  <span className={styles.menuIcon} style={{ color: item?.data?.color }}>
                    <CircleIcon />
                  </span>
                </div>
              );
            })}
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
};
