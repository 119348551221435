import { api } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';

export const PlanTreeAPI = {
  get: async function (goalId, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goaltree/${goalId}`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  createSubGoal: async function (data, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/subgoals/`,
      method: 'POST',
      data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  updateParentGoal: async function (goalId, newParentGoalId) {
    const response = await api.request({
      url: `/api/plantree/subgoals/${goalId}/update_parent/`,
      method: 'PUT',
      data: {
        new_parent_goal_id: newParentGoalId,
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to update parent goal");
    }
    return response.data;
  }

};

const cancelApiObject = defineCancelApiObject(PlanTreeAPI);
