import React from 'react';
import { getSmoothStepPath } from 'reactflow';
import styles from './edgeTypes.module.css';

export default function SubGoalEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  offsetX = -15,
}) {
  const modifiedSourceX = sourceX + offsetX;
  const modifiedTargetX = targetX + offsetX;

  const [edgePath] = getSmoothStepPath({
    sourceX: modifiedSourceX,
    sourceY,
    sourcePosition,
    targetX: modifiedTargetX,
    targetY,
    targetPosition,
    borderRadius: 16,
  });

  return (
    <>
      <path id={id} style={style} className={styles.edgePath} d={edgePath} markerEnd={markerEnd} />
    </>
  );
}
