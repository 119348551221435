import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import { ConnectExternalCalendar } from './ConnectExternalCalendar';
import { CalendarAPI } from '../../services/calendar/calendarService';
import { formatConnections } from './logics';
import styles from './styles.module.scss';
import { AppContext } from '../../contexts/appContext';

export default function ConnectExternalCalendarPopup() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [connections, setConnections] = useState([]);
  const { retrieveEvents, selectedWeek } = useContext(AppContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getConnections();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getConnections = () => {
    CalendarAPI.connections().then((response) => {
      if (response) {
        const formattedData = formatConnections(response.data);
        setConnections(formattedData);
      }
    });
  };

  const updateSettings = (connection) => {
    const { email, ...rest } = connection;
    const obj = { [email]: rest };
    CalendarAPI.updateConnection(obj).then((response) => {
      if (response) {
        let connectionState = [...connections];
        let foundIndex = connectionState.findIndex((item) => item.email == connection.email);
        connectionState[foundIndex] = connection;
        setConnections(connectionState);
        const { startDate, endDate } = selectedWeek;
        // const startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
        // const endDate = lastDayOfWeek(new Date(), { weekStartsOn: 1 });
        retrieveEvents(startDate, endDate);

      }
    });
  };
  const updateConnectionsData = (email) => {
    const cData = [...connections];
    const filteredItem = cData.filter((el) => el.email !== email);
    setConnections(filteredItem);
  };

  return (
    <div>
      <div className='setting-icon' onClick={handleClick}>
        <img src='/images/calendar/setting.svg' />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.externalCalendarContainer}>
          <ConnectExternalCalendar
            connections={connections}
            updateSettings={updateSettings}
            closeParent={handleClose}
            updateConnectionsData={updateConnectionsData}
          />
        </div>
      </Popover>
    </div>
  );
}
