import { api } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';

export const MyGoalsAPI = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goals/${id}/`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getAll: async function (cancel = false) {
    try {
      const response = await api.request({
        url: '/api/plantree/goals/',
        method: 'GET',
        signal: cancel
          ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
          : undefined,
      });

      return response;
    } catch (error) {
      return error.response.data;
    }
  },

  create: async function (goal, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goals/`,
      method: 'POST',
      data: goal,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goals/${id}/`,
      method: 'DELETE',
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  deleteSubGoal: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/subgoals/${id}/`,
      method: 'DELETE',
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  update: async function (id, goal, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goals/${id}/`,
      method: 'PATCH',
      data: goal,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  modify: async function (id, goal, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/goals/${id}/`,
      method: 'PATCH',
      data: goal,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  modifySubgoal: async function (id, goal, cancel = false, retries = 3, delay = 1000) {
    if (retries <= 0) {
      throw new Error('Maximum number of retries exceeded');
    }

    try {
      const response = await api.request({
        url: `/api/plantree/subgoals/${id}/`,
        method: 'PATCH',
        data: goal,
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (error) {
      // If the request fails, wait for the delay duration and then retry
      await new Promise(resolve => setTimeout(resolve, delay));
      return this.modifySubgoal(id, goal, cancel, retries - 1, delay);
    }
  },

};

const cancelApiObject = defineCancelApiObject(MyGoalsAPI);
