import * as React from 'react';
import styles from './mobile.module.scss';

const MobileWarning = () => {
    return (
        <div className={styles.mobileWarningContainer}>
            <h1><img src="/images/header/logo.svg" alt="Wayy logo" /> is currently only available on desktop devices</h1>
            <p>If you&apos;re using a mobile device then please go to <a href="https://platform.wayy.life">platform.wayy.life</a> on your computer to sign in.</p>
            <p>If you&apos;re using a browser on a laptop or desktop, then please increase the width of your browser window.</p>
        </div>
    );
};

export default MobileWarning;
