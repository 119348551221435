import SubGoalNode from './SubGoalNode';
import ActionNode from './ActionNode';
import GoalNode from './GoalNode';

// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes = {
  workflow: SubGoalNode,
  enterAction: ActionNode,
  tabAction: ActionNode,
  goal: GoalNode,
};

export default nodeTypes;
