import { GoalsContext } from '../../contexts/goalsContext';
import styles from './styles.module.scss';
import React, { memo, useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactFlow } from 'reactflow';
import { constants } from './constants';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { subgoalActionsSelector } from '../../redux/selectors';

const DeleteGoalDialog = () => {
  const { getNodes, getEdges } = useReactFlow();
  const { deleteGoal, openDeleteModal, setOpenDeleteModal, selectedGoalForMenu } =
    useContext(GoalsContext);
  const [open, setOpen] = useState(false);
  const [goalName, setGoalName] = useState('');
  const [actionsCount, setActionCount] = useState(0);
  const [subGoalsCount, setSubGoalsCount] = useState(0);
  const [isMainGoal, setIsMainGoal] = useState();
  const actions = useSelector(state => subgoalActionsSelector(state, selectedGoalForMenu));

  useEffect(() => {
    if (openDeleteModal) {
      setOpen(true);
      const nodes = getNodes();
      const goalName = nodes.find((node) => node?.data?.goalId === selectedGoalForMenu)?.data.label;
      setIsMainGoal(nodes.find((node) => node?.data?.goalId === selectedGoalForMenu)?.data.mainGoal);
      setGoalName(goalName);
      // Display all required warnings
      setActionCount(actions.length);
      const edges = getEdges();
      const subGoals = edges.filter(
        (edge) => edge.source == selectedGoalForMenu && edge.type === 'workflow',
      );
      setSubGoalsCount(subGoals.length);
    } else {
      setOpen(false);
      setOpenDeleteModal(false);
      setGoalName('');
    }
  }, [openDeleteModal, setOpenDeleteModal]);

  const handleClose = () => {
    setOpen(false);
    setOpenDeleteModal(false);
  };

  const handleDeleteGoal = () => {
    setOpen(false);
    setOpenDeleteModal(false);
    deleteGoal();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <DialogTitle id='alert-dialog-title' className={styles.goalTitle}>
              {constants.DELETE} {goalName}?
            </DialogTitle>
          </Grid>
          <Grid item xs={11}>
            <DialogContent>
              {/* <DialogContentText id='alert-dialog-description'>
                {isMainGoal ? (
                  <div className={styles.secondaryLabel}>
                    Are you sure you want to permanently delete this entire goal? All of the subgoals and actions in it will be IRREVERSIBLY deleted as well.
                  </div>
                ) : (
                  <>
                    {actionsCount > 0 && (
                      <div className={styles.secondaryLabel}>
                        {`It contains ${actionsCount} ${actionsCount > 1 ? 'actions' : 'action'} that will be deleted too.`}
                      </div>
                    )}
                    {subGoalsCount > 0 && (
                      <div className={styles.secondaryLabel}>
                        {`It contains ${subGoalsCount} ${subGoalsCount > 1 ? 'subGoals' : 'subGoal'} that will be deleted too.`}
                      </div>
                    )}
                  </>
                )}
              </DialogContentText> */}
              <DialogContentText>
                <div className={styles.secondaryLabel}>
                  Are you sure you want to permanently delete this entire goal? All of the subgoals and actions in it will be IRREVERSIBLY deleted as well.
                </div>
              </DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
        <DialogActions className={styles.actionContainer}>
          <Grid container spacing={1}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Button className={styles.goBack} onClick={handleClose}>
                {constants.GO_BACK}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button className={styles.delete} onClick={() => { handleDeleteGoal() }}>
                {constants.DELETE}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(DeleteGoalDialog);
