import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import { uuid } from '../../../utils/utils';
import DeleteActionDialog from '../../deleteDialogs/DeleteActionDialog';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ScheduleActionPopup from '../contextMenu/ScheduleActionPopup';
import ConfirmationPopup from '../../confirmationPopup/ConfirmationPopup';
import SubgoalActionsListElement from './SubgoalActionsListElement';
import { Draggable } from 'devextreme-react';
import { AppContext } from '../../../contexts/appContext';

const SubgoalActionsList = ({
  actions = [],
  saveAction,
  duplicateActions,
  deleteAction,
  toggleAchievedAction,
  id,
  setShowList = () => { },
}) => {
  const [inputState, setInputState] = useState(false);
  const [editActionId, setEditActionId] = useState('');
  const [deleteActionId, setDeleteActionId] = useState('');
  const [deleteActionName, setDeleteActionName] = useState('');
  const [input, setInput] = useState('');
  const [selectedAction, setSelectedAction] = useState({});
  const [startDragging, setStartDragging] = useState(false);
  const [currentEvent, setCurrentEvent] = useState();
  const [currentAction, setCurrentAction] = useState();
  const [isSchedulePopupOpen, setIsSchedulePopupOpen] = useState(false);
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
  const { color } = useSelector((state) => state.currentGoalColor);
  const { dragStart, dragEnd, handleMoveToQueue, setMessage, setIsOpenAlert, draggedActionForReordering, hasMoved, dragEntered } = useContext(AppContext);

  const addActionData = () => {
    setInputState((inputState) => !inputState);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSaveAction();
    }
  };

  const handleSaveAction = () => {
    if (!input) return;
    let obj = {};
    if (editActionId) {
      obj = {
        name: input,
        id: editActionId,
      };
    } else {
      obj = {
        name: input,
        id: uuid(),
      };
    }
    saveAction(obj, editActionId);
    setInputState(false);
    setInput('');
    setEditActionId('');
  };

  const deleteActionById = () => {
    deleteAction(deleteActionId, deleteActionName);
  };

  const handleMouseDown = (actionId) => {
    setStartDragging(actionId);
  };

  const handleMouseUp = () => {
    setStartDragging('');
  };

  const handleSchedulePopupOpen = () => {
    setIsSchedulePopupOpen(false);
  };

  const handleSubmit = () => {
    toggleAchievedAction(selectedAction);
    setIsOpenConfirmationPopup(false);
  };

  const handleOnDragStart = (e) => {
    // const dataTransfer = e.event.fromData;
    // if (dataTransfer) {
    //   dataTransfer.setData('text/plain', 'Your data here');
    // }
    // e.stopPropagation();
    // e.preventDefault();
    dragStart(e);
    setStartDragging('');
  };

  // onDragStart={(e) => {
  //   const dataTransfer = e.event.dataTransfer;
  //   if (dataTransfer) {
  //     dataTransfer.setData('text/plain', 'Your data here');
  //   }
  //   // Your existing logic...
  // }}

  const onDragEnd = (e) => {
    if (e.toData == "dropArea") {
      const action = actions.find((a) => a.id === e.fromData.id)
      if (!action.completed) {
        handleMoveToQueue(action);
      } else {
        e.cancel = true;
        setMessage("Completed action can't be moved to queue");
        setIsOpenAlert(true);
      }
    } else if (e.toData && dragEntered.current === true) {
      if (e.toData.id === draggedActionForReordering.id) {
        const action = actions.find((a) => a.id === e.toData.id)
        handleMoveToQueue(action);
      }
    }
    hasMoved.current = false;
    dragEntered.current = false;
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <Typography>Actions</Typography>
        <CloseIcon fontSize='xs' onClick={() => setShowList(false)} style={{ cursor: "pointer" }} />
      </div>

      <div className={styles.marginContainer}>
        <div id='list' className={styles.draggableContainer}>
          {inputState && (
            <Paper elevation={0} className={styles.actionItemContainer}>
              <FormControl className={`${styles.actionInputContainer}`}>
                <OutlinedInput
                  className={styles.actionAdornment}
                  startAdornment=<InputAdornment position='start'>
                    <IconButton aria-label='Action status - new' sx={{ padding: '0px' }}>
                      {<CircleIcon sx={{ color: color, fontSize: '16px', padding: '0px' }} />}
                    </IconButton>
                  </InputAdornment>
                  size='small'
                  autoFocus={true}
                  onChange={handleInputChange}
                  value={input}
                  onKeyDown={handleKeyDown}
                  onBlur={handleSaveAction}
                />
              </FormControl>
            </Paper>
          )}
          {actions.map((action, index) => {
            return (
              <Draggable
                key={action.id}
                onDragEnd={onDragEnd}
                onDragStart={handleOnDragStart}
                style={{ zIndex: "1000" }}
                data={actions.find((a) => a.id === action.id)}
                group="calendarLayoutGroup"
                clone={true}    >

                <Paper
                  elevation={0}
                  className={
                    styles.actionItemContainer +
                    ' ' +
                    (startDragging === action.id ? styles.startDragging : '')
                  }
                  key={index}
                  onMouseDown={() => handleMouseDown(action.id)}
                  onMouseUp={handleMouseUp}
                >

                  <SubgoalActionsListElement
                    actionId={action.id}
                    index={index}
                    editActionId={editActionId}
                    setEditActionId={setEditActionId}
                    input={input}
                    setInput={setInput}
                    setDeleteActionId={setDeleteActionId}
                    setDeleteActionName={setDeleteActionName}
                    setIsOpenConfirmationPopup={setIsOpenConfirmationPopup}
                    setSelectedAction={setSelectedAction}
                    setIsSchedulePopupOpen={setIsSchedulePopupOpen}
                    setStartDragging={setStartDragging}
                    setCurrentEvent={setCurrentEvent}
                    setCurrentAction={setCurrentAction}
                    id={id}
                    deleteAction={deleteAction}
                    duplicateActions={duplicateActions}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                    handleSaveAction={handleSaveAction}
                    toggleAchievedAction={toggleAchievedAction}
                    saveAction={saveAction}
                  />

                </Paper>
              </Draggable>
            );
          })}
        </div>
        <Button variant='Tertiary' onClick={addActionData} className={styles.addActionBtn}>
          <AddIcon fontSize='small' style={{ marginRight: "8px" }} />
          New Action
        </Button>
      </div>

      <DeleteActionDialog
        deleteActionId={deleteActionId}
        actionName={deleteActionName}
        setDeleteActionId={setDeleteActionId}
        deleteAction={deleteActionById}
      />
      <ScheduleActionPopup
        isQueueAction={false}
        action={currentAction}
        fromCalendar={false}
        isOutsideContextMenu={true}
        currentEvent={currentEvent}
        isOverflow={false}
        isSchedulePopupOpen={isSchedulePopupOpen}
        handleSchedulePopupOpen={handleSchedulePopupOpen}
      />
      <ConfirmationPopup
        heading={'Marking Series of Action done will stop repeating the Action.'}
        subHeading={`Do you want to stop repeating the Action “${selectedAction.name}”?`}
        submitButtonText={'Stop repeating and Action achieved'}
        isOpen={isOpenConfirmationPopup}
        backAction={() => setIsOpenConfirmationPopup(false)}
        submitAction={handleSubmit}
      />
    </>
  );
};

export default SubgoalActionsList;
