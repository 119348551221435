import React, { useContext } from 'react';
import { getSmoothStepPath } from 'reactflow';
import { GoalsContext } from '../../contexts/goalsContext';
import styles from './edgeTypes.module.css';

export default function PlaceholderEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  offsetX = -15,
}) {
  const modifiedSourceX = sourceX + offsetX;
  const modifiedTargetX = targetX + offsetX;

  const [edgePath] = getSmoothStepPath({
    sourceX: modifiedSourceX,
    sourceY,
    sourcePosition,
    targetX: modifiedTargetX,
    targetY,
    targetPosition,
    borderRadius: 16,
  });

  const { createNewNode } = useContext(GoalsContext);

  const handleCreateNewNode = (e) => {
    e.stopPropagation();
    createNewNode();
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className={styles.placeholderPath}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <g transform={`translate(${modifiedTargetX}, ${targetY})`}>
        <circle
          cx='0'
          cy='0'
          r='10'
          stroke='black'
          strokeWidth='2'
          fill='white'
          className={styles.edgeButton}
          onClick={handleCreateNewNode}
        />
        <text className={styles.edgeButtonSym} y={3.5} x={-3.5}>
          +
        </text>
        <text className={styles.edgeButtonText} y={25} x={-20}>
          ENTER
        </text>
      </g>
    </>
  );
}
