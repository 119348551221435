import React, { memo, useContext } from 'react';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import { GoalsContext } from '../../contexts/goalsContext';
import { useTranslation } from 'react-i18next';

const AddGoal = () => {
  const { t } = useTranslation();
  const { createNewGoal } = useContext(GoalsContext);
  return (
    <div className={styles.setGoalBtnContainer}>
      <Button className={styles.setGoalBtn} onClick={createNewGoal}>
        {t('set.goal')}
      </Button>
    </div>
  );
};

export default memo(AddGoal);
