import React, { memo, useContext } from 'react';
import styles from './colorPicker.module.scss';
import { GoalsContext } from '../../../contexts/goalsContext';
import { useReactFlow } from 'reactflow';
import { MyGoalsAPI } from '../../../services/myGoals';

const ColorPicker = () => {
  const { selectedGoalForMenu, closeContextMenu, setMenuAction } = useContext(GoalsContext);
  const { setNodes, getNodes } = useReactFlow();

  const changeColor = (color) => {
    const nodes = getNodes();
    MyGoalsAPI.modify(selectedGoalForMenu, { color: color }).then(() => {
      nodes.forEach((element) => {
        if (element.data.goalId === selectedGoalForMenu) {
          element.data.color = color;
        }
      });
      setNodes([...nodes]);
      setMenuAction('');
      closeContextMenu();
    });
  };

  return (
    <div className={styles.colorPickerContainer}>
      <div className={styles.column}>
        <div className={styles.item1} onClick={() => changeColor('#5696f1')}></div>
        <div className={styles.item2} onClick={() => changeColor('#7ba369')}></div>
        <div className={styles.item3} onClick={() => changeColor('#d5af29')}></div>
      </div>
      <div className={styles.column}>
        <div className={styles.item4} onClick={() => changeColor('#a27272')}></div>
        <div className={styles.item5} onClick={() => changeColor('#094d49')}></div>
        <div className={styles.item6} onClick={() => changeColor('#cf1b92')}></div>
      </div>
      <div className={styles.column}>
        <div className={styles.item7} onClick={() => changeColor('#501414')}></div>
        <div className={styles.item8} onClick={() => changeColor('#4e279f')}></div>
        <div className={styles.item9} onClick={() => changeColor('#d62f2f')}></div>
      </div>
    </div>
  );
};

export default memo(ColorPicker);
