import { api } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';
import { debounce } from 'lodash';

export const ActionAPI = {
  getAllActionsByGoalId: async function (goalId, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/?parent_goal=${goalId}`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${id}/`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getAll: async function (cancel = false) {
    try {
      const response = await api.request({
        url: '/api/plantree/actions/',
        method: 'GET',
        signal: cancel
          ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  create: async function (action, cancel = false, queue = false) {
    const queryParams = queue ? '?queue=true' : '';
    const response = await api.request({
      url: `/api/plantree/actions/${queryParams}`,
      method: 'POST',
      data: action,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${id}/`,
      method: 'DELETE',
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  update: async function (id, action, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${id}/`,
      method: 'PUT',
      data: action,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getAllCompletedActions: async function (cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/?completed=True`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  bulkComplete: async function (actionIds, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/complete`,
      method: 'POST',
      data: actionIds,
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  modify: async function (actionId, actionData, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${actionId}/`,
      method: 'PATCH',
      data: actionData,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  createSchedule: async function (actionId, data, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${actionId}/schedule/`,
      method: 'POST',
      data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  modifySchedule: async function (actionId, data, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${actionId}/schedule/`,
      method: 'PATCH',
      data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  modifyScheduleDebounced: debounce(async function (actionId, data, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${actionId}/schedule/`,
      method: 'PATCH',
      data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  }, 1000),

  getEvents: async function (start_date, end_date, cancel = false) {
    const response = await api.request({
      url: `/api/calendar/eventsInRange/?start_date=${start_date}&end_date=${end_date}`,
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  deleteFromCalendar: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/plantree/actions/${id}/schedule/`,
      method: 'DELETE',
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(ActionAPI);
