import React, { useState, useEffect } from 'react';
import { ActionAPI } from '../../services/actions/actionService';
import ConnectExternalCalendarPopup from './ConnectExternalCalendarPopup';
import { Grid } from '@mui/material';
import styles from './styles.module.scss';

export default function FilterContainer({ selectCurrentView }) {
  const [view, setView] = useState('day'); // Initialize view as 'day'
  const [iconNumber, setIconNumber] = useState(1);
  const [completedAction, setCompletedAction] = useState([]);

  useEffect(() => {
    if (!completedAction?.length) {
      ActionAPI.getAllCompletedActions().then((action) => {
        setCompletedAction(action);
      });
    }
  }, []);

  const handleToggleView = () => {
    if (view === 'day') {
      setView('week');
      setIconNumber(7);
      selectCurrentView('week');
    } else {
      setView('day');
      setIconNumber(1);
      selectCurrentView('day');
    }
  };

  return (
    <>
      <Grid container style={{ alignItems: "flex-start" }}>
        <Grid item md={8}>
          <img src='/images/header/logo.svg' alt='logo' style={{ marginLeft: "12px", width: "100px", paddingTop: "4px" }} />
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={3} className={styles.calendarSettingContainer}>
          <div className='check-one-icon'>
            <div onClick={handleToggleView}>
              {iconNumber === 1 && <img src='/images/calendar/calendar-one.svg' />}
              {iconNumber === 7 && <img src='/images/calendar/calendar-seven.svg' />}
            </div>
          </div>
          <ConnectExternalCalendarPopup actions={completedAction} />
        </Grid>
      </Grid>
    </>
  );
}