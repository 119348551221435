import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'All saved',
  treeCreators: [],
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    clearStatus: (state) => {
      state.status = '';
    },
  },
});

export const { setStatus, clearStatus } = headerSlice.actions;

export default headerSlice.reducer;
