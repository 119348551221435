import { authApi } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';

export const AuthAPI = {
  login: async function (userData, cancel = false) {
    try {
      const response = await authApi.request({
        url: `/api/auth/login/`,
        method: 'POST',
        data: userData,
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  register: async function (userData, cancel = false) {
    try {
      const response = await authApi.request({
        url: `/api/auth/register/`,
        method: 'POST',
        data: userData,
        signal: cancel
          ? cancelApiObject[this.create.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
  logout: async function (refreshToken, cancel = false) {
    try {
      const response = await authApi.request({
        url: `/api/auth/logout/`,
        method: 'POST',
        data: { refresh_token: refreshToken },
        signal: cancel
          ? cancelApiObject[this.logout.name].handleRequestCancellation().signal
          : undefined,
      });
      return response;
    } catch (error) {
      return error?.response;
    }
  },
};

const cancelApiObject = defineCancelApiObject(AuthAPI);
