import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  goals: [],
};

export const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    addGoal: (state, action) => {
      state.goals = [...state.goals, action.payload];
    },
    setGoals: (state, action) => {
      state.goals = [...action.payload];
    },
    deleteGoal: (state, action) => {
      const updatedGoals = state?.goals.filter((goal) => goal.id !== action.payload);
      state.goals = [...updatedGoals];
    },
    getGoals: (state) => state.goals,
  },
});

export const { addGoal, setGoals, deleteGoal, getGoals } = goalSlice.actions;

export default goalSlice.reducer;
