import React, { Routes, Route, Navigate } from 'react-router-dom';
import { MyGoals } from '../pages/myGoals';
import { PlanTree } from '../pages/planTree';
import { CalendarLayout } from '../layouts';
import { EventsProvider } from '../contexts/eventsContext';
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

function ProtectedRoute({ children }) {
  return (
    <>
      <SignedIn>
        {children}
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}

function routes() {
  return (
    <EventsProvider>
      <Routes>
        <Route path='/' element={<Navigate to='/goals' />} />
        {/* <Route path='/signin' element={<SignIn />} />
        <Route path='/registration' element={<Registration />} /> */}
        <Route element={<ProtectedRoute><CalendarLayout /></ProtectedRoute>}>
          <Route path='/goals' element={
            <ProtectedRoute>
              <MyGoals />
            </ProtectedRoute>
          } />
          <Route path='/goals/:id' element={
            <ProtectedRoute>
              <PlanTree />
            </ProtectedRoute>
          } />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Routes>
    </EventsProvider>
  );
}

const NoMatch = () => {
  return <p>{"There's nothing here: 404!"}</p>;
};

export default routes;
