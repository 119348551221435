import React, { memo, useState, useContext, useEffect } from 'react';
import styles from './contextMenu.module.scss';
import { Divider } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { constants } from './constants';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import ScheduleActionPopup from './ScheduleActionPopup';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import { ConnectToGoalPopup } from './ConnectToGoalPopup';
import ConfirmationPopup from '../../confirmationPopup/ConfirmationPopup';
import RepeatIcon from '@mui/icons-material/Repeat';
import { AppContext } from '../../../contexts/appContext';
import { actionsSelector } from '../../../redux/selectors';

const ActionContextMenu = ({
  actionObj,
  editAction = () => { },
  deleteAction = () => { },
  duplicateAction = null,
  moveToQueueAction = () => { },
  handleRemoveFromQueue = () => { },
  handleSaveNote = () => { },
  toggleAchievedAction = () => { },
  deleteActionFromCalendar = () => { },
  isQueueAction,
  editActionId,
  moreOption,
  showEventIcon = false,
  alwaysShow = false,
  fromCalendar = false,
  overFlowAction,
}) => {
  const { queueItems } = useSelector((state) => state.queueItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenToggleRepetitiveActionConfirmationPopup, setIsOpenToggleRepetitiveActionConfirmationPopup] = React.useState(false);
  const [noteInput, setNoteInput] = useState(actionObj.notes);
  const { setMessage, setIsOpenAlert } = useContext(AppContext);
  const actions = useSelector(state => actionsSelector(state)) || [];
  const currentAction = actions.find(action => action.id === actionObj.id);
  const [isOpenMoveToQueuePopup, setIsOpenMoveToQueuePopup] = React.useState(false);

  useEffect(() => {
    if (currentAction) {
      setNoteInput(currentAction.notes);
    }
  }, [currentAction]);

  const handleClickContextMenu = (e, action) => {
    e.stopPropagation();
    if (action === 'rename') {
      editAction(actionObj);
    } else if (action === 'delete') {
      deleteAction(actionObj);
    } else if (action === 'duplicate') {
      duplicateAction(actionObj);
    } else if (action === 'moveToQueue') {
      moveToQueueAction(actionObj);
    } else if (action === 'action_achieved') {
      if (actionObj?.repetitive) {
        setIsOpenToggleRepetitiveActionConfirmationPopup(true);
      } else {
        toggleAchievedAction(actionObj);
      }
    } else if (action === 'deleteFromCalendar') {
      deleteActionFromCalendar(actionObj);
    }
    handleClose();
  };

  const AddAndRemoveFromQueue = (e) => {
    if (!actionObj.completed) {
      e.stopPropagation();
      if (isQueueAction || isQueuedItem()) {
        handleRemoveFromQueue(actionObj);
      } else {
        if (actionObj.repetitive) {
          setIsOpenMoveToQueuePopup(true);
        } else {
          moveToQueueAction(actionObj);
        }
      }
      handleClose();
    }
    else {
      e.cancel = true;
      setMessage("Completed action can't be moved to queue");
      setIsOpenAlert(true);
    }
  };

  const handleBackQueueAction = () => {
    setIsOpenMoveToQueuePopup(false);
  };

  const handleSubmitQueueAction = () => {
    moveToQueueAction(actionObj);
    setIsOpenMoveToQueuePopup(false);
  };

  const isQueuedItem = () => {
    return queueItems.some((item) => item === actionObj.id);
  };

  const queuedIndex = () => {
    return queueItems.findIndex((item) => item === actionObj.id);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDenyTogglingRepetitiveActionCompleted = () => {
    setIsOpenToggleRepetitiveActionConfirmationPopup(false);
  };

  const handleProceedWithTogglingRepetitiveAction = () => {
    toggleAchievedAction(actionObj);
    setIsOpenToggleRepetitiveActionConfirmationPopup(false);
  };

  const saveNote = () => {
    handleSaveNote(noteInput, actionObj);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSaveNote(noteInput, actionObj);
      handleClose();
    }
  };

  const handleWriteNote = (e) => {
    setNoteInput(e.target.value);
  };

  const textFieldStyle = {
    '& label.Mui-focused': {
      color: '#e35249',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#E4EFF8',
      borderRadius: "8px"
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E4EFF8',
        borderRadius: "8px"
      },
      '&:hover fieldset': {
        borderColor: '#E4EFF8',
        borderRadius: "8px"
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e35249',
        borderRadius: "8px"
      },
    },
  }

  return (
    <div>
      {editActionId !== actionObj.id && (
        <div
          aria-describedby={id}
          className={fromCalendar ? styles.actionsCalendarContainer : styles.actionsContainer}
        >
          {(isQueuedItem() || showEventIcon) && !isQueueAction && (
            <IconButton aria-label='calendar'>
              {<img src='/images/action/calendar.svg' />}
            </IconButton>
          )}
          {actionObj.repetitive && (
            <IconButton aria-label='calendar' sx={{
              background: "white !important",
              '&:hover': {
                background: 'white !important',
              }
            }}>
              {
                <RepeatIcon
                  sx={{
                    fontSize: '16px',
                    borderRadius: '50%',
                    color: '#0c2340 !important',
                    backgroundColor: 'white !important',
                    '&:hover': {
                      backgroundColor: 'white !important',
                    }
                  }}
                />
              }
            </IconButton>
          )}
          <IconButton
            id="moreOptionsButton"
            onClick={handleClick}
            aria-label='more options'
            className={cx(
              moreOption == actionObj.id || alwaysShow
                ? styles.visibleMoreOption
                : styles.hideMoreOption,
              'moreOptionsIcon',
            )}
            style={fromCalendar ? { paddingLeft: '8px' } : {}}
            sx={{
              '&:hover': {
                backgroundColor: '#dadadc !important',
              }
            }}
          >
            {
              <MoreHorizIcon
                sx={{
                  fontSize: '16px',
                  borderRadius: '50%',
                  color: fromCalendar ? '#000' : '#000',
                }}
              />
            }
          </IconButton>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ margin: '-12px 0 0 14px' }}
      >
        <div
          className={cx(
            !isQueueAction ? styles.actionContextMenuContainer : styles.queueContextMenuContainer,
          )}
          id='context-menu'
        >
          {isQueuedItem() && (
            <div className={`${styles.menu} ${styles.menuitemDisable} ${styles.actionPositionInQueueTextMenuWrapper}`}>
              <div className={`${styles.menuItem} ${styles.menuitemDisable} ${styles.actionPositionInQueueText}`}>
                <span className={styles.menuTextNormal}>
                  {' '}
                  Action #{queuedIndex() + 1} in Queue{' '}
                </span>
              </div>
            </div>
          )}

          <ScheduleActionPopup
            isQueueAction={isQueueAction}
            action={actionObj}
            handleParentClose={handleClose}
            fromCalendar={fromCalendar}
            isOverflow={overFlowAction}
            deleteFromCalendar={deleteActionFromCalendar}
            isOutsideContextMenu={false}
          />

          <div className={styles.menu} onClick={(e) => AddAndRemoveFromQueue(e)}>
            <div className={styles.menuItem}>
              <span className={styles.menuTextNormal}>
                {actionObj.repetitive
                  ? constants.MOVE_TO_QUEUE_REPETITIVE
                  : (isQueueAction || isQueuedItem()
                    ? constants.REMOVE_FROM_QUEUE
                    : constants.MOVE_TO_QUEUE)}
              </span>
              <span className={styles.menuTextGrey}>
                {isQueueAction && isQueuedItem() ? constants.BACKSPACE : constants.Q_KEY}
              </span>
            </div>
          </div>
          <div
            className={styles.menu}
            onClick={(e) => handleClickContextMenu(e, 'action_achieved')}
          >
            <div className={styles.menuItem}>
              <span className={styles.menuIcon}>
                {actionObj.completed ? (
                  <CheckCircleOutlineIcon sx={{ color: actionObj.color, fontSize: '22px', padding: '0px' }} />
                ) : (
                  <CircleIcon sx={{ color: actionObj.color, fontSize: '22px', padding: '0px' }} />
                )}

              </span>
              <span className={styles.menuTextNormal}>
                {' '}
                {actionObj.completed ? constants.ACTION_UNCOMPLETE : constants.ACTION_COMPLETED}
              </span>
              <span className={styles.menuTextGrey}>{constants.A_KEY}</span>
            </div>
          </div>
          <div className={styles.actionDivider}>
            <Divider />
          </div>
          <div className={styles.writeNoteMenu}>
            <div className={styles.writeNoteMenuItem}>
              <span className={styles.writeNoteMenuTextNormal}>{constants.WRITE_NOTE}</span>
              <span className={styles.writeNoteMenuTextGrey}>{constants.N_KEY}</span>
            </div>
            <div>
              <Box sx={{ width: '100%' }}>
                <TextField
                  sx={textFieldStyle}
                  rows={2}
                  multiline
                  fullWidth
                  id='fullWidth'
                  value={noteInput}
                  onKeyDown={handleKeyDown}
                  onBlur={saveNote}
                  onChange={handleWriteNote}
                  InputProps={{ style: { fontSize: 12.5, fontFamily: "Gellix Regular" } }}
                />
              </Box>
            </div>
          </div>
          <div className={styles.actionDivider}>
            <Divider />
          </div>
          {!actionObj.parent_goal && isQueueAction && (
            <>
              <div className={`${styles.menu} ${styles.menuitemDisable} ${styles.menuitemNoGoal}`}>
                <span className={`${styles.menuTextNormal} ${styles.menuItemNogoalText}`}>
                  {' '}
                  {constants.CONNECT_GOAL}{' '}
                </span>
              </div>
              <div className={styles.actionDivider}>
                <Divider />
              </div>
              <ConnectToGoalPopup action={actionObj} handleParentClose={handleClose} />
            </>
          )}
          {!fromCalendar ? (
            <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'rename')}>
              <div className={styles.menuItem}>
                <span className={styles.menuTextNormal}>{constants.RENAME}</span>
                <span className={styles.menuTextGrey}>{constants.SPACEBAR}</span>
              </div>
            </div>
          ) : (
            <div className={`${styles.menu} ${styles.menuItemDisclaimer}`}>
              <div className={`${styles.menuItem} ${styles.menuItemDisclaimer}`}>
                <span className={styles.menuTextNormal} style={{ fontSize: "10px" }}>You can rename the action in the plan tree </span>
              </div>
            </div>
          )}

          {/* <div className={styles.menu} style={{ display: duplicateAction ? "block" : "none" }} onClick={(e) => handleClickContextMenu(e, 'duplicate')}>
            <div className={styles.menuItem}>
              <span className={styles.menuTextNormal}>{constants.DUPLICATE}</span>
              <span className={styles.menuTextGrey}>{constants.D_KEY}</span>
            </div>
          </div> */}
          {/* <div className={styles.actionDivider}>
            <Divider />
          </div> */}
          {fromCalendar && (
            <div
              className={styles.menu}
              onClick={(e) => handleClickContextMenu(e, 'deleteFromCalendar')}
            >
              <div className={styles.menuItem}>
                <span className={styles.menuTextNormal}>{constants.DELETE_FROM_CALENDAR}</span>
                <span className={styles.menuTextGrey}>{constants.BACKSPACE}</span>
              </div>
            </div>
          )}
          <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'delete')}>
            <div className={styles.menuItem}>
              <span className={styles.menuTextNormal}>
                {(isQueueAction && actionObj.goalId) || fromCalendar
                  ? constants.DELETE_COMPLETELY
                  : constants.DELETE}
              </span>
              <span className={styles.menuTextGrey}>
                {' '}
                {(isQueueAction && actionObj.goalId) || fromCalendar
                  ? constants.SHIFT_BACKSPACE
                  : constants.BACKSPACE}
              </span>
            </div>
          </div>
        </div>
      </Popover >
      <ConfirmationPopup
        heading={'Toggling a repetitive Action "(un)completed" will toggle all of its instances.'}
        subHeading={`Currently toggling a repetitive action "completed" will toggle of its instances as "(un)completed". We are working to add the option to only toggle one instance. Do you wish to proceed?`}
        submitButtonText={'Toggle all instances of the action "(un)completed"'}
        isOpen={isOpenToggleRepetitiveActionConfirmationPopup}
        backAction={handleDenyTogglingRepetitiveActionCompleted}
        submitAction={handleProceedWithTogglingRepetitiveAction}
      />
      <ConfirmationPopup
        heading={'Moving a repetitive Action to the Queue will disable the repetition.'}
        subHeading={`Do you want to disable the repetition for the Action “${actionObj?.name}” and move to Queue?`}
        submitButtonText={'Move to Queue and disable repetition'}
        isOpen={isOpenMoveToQueuePopup}
        backAction={handleBackQueueAction}
        submitAction={handleSubmitQueueAction}
      />
    </div >
  );
};

export default memo(ActionContextMenu);
