import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  color: '',
};

export const currentGoalColorSlice = createSlice({
  name: 'currentGoalColor',
  initialState,
  reducers: {
    setCurrentGoalColor: (state, action) => {
      state.color = action.payload;
    },
  },
});

export const { setCurrentGoalColor } = currentGoalColorSlice.actions;

export default currentGoalColorSlice.reducer;
