import { Model, fk, attr } from 'redux-orm';
import { createAction } from '@reduxjs/toolkit';

class Event extends Model {
    static get fields() {
        return {
            id: attr(),
            actionId: fk({ to: 'Action', as: 'action', relatedName: 'events' }),
            startDate: attr(),
            endDate: attr(),
            allDay: attr(),
        };
    }

    static reducer(action, Event) {
        switch (action.type) {
            case addEvent.toString():
                Event.create(action.payload);
                break;
            case updateEvent.toString():
                Event.withId(action.payload.actionId)?.update(action.payload);
                break;
            case updateEvents.toString():
                action.payload.forEach(event => Event.withId(event.id).update(event));
                break;
            case deleteEvent.toString():
                Event.withId(action.payload).delete();
                break;
            case setEvents.toString():
                Event.all().delete();
                action.payload.forEach(event => Event.create(event));
                break;
            case clearEvents.toString():
                Event.all().delete();
                break;
            default:
                break;
        }
    }
}
Event.modelName = 'Event';

export const addEvent = createAction('ADD_EVENT');
export const updateEvent = createAction('UPDATE_EVENT');
export const updateEvents = createAction('UPDATE_EVENTS');
export const deleteEvent = createAction('DELETE_EVENT');
export const setEvents = createAction('SET_EVENTS');
export const clearEvents = createAction('CLEAR_EVENTS');

export default Event;