import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import { CalendarAPI } from '../../services/calendar/calendarService';
import styles from './styles.module.scss';
import IconButton from '@mui/material/IconButton';

const ConnectExternalCalendar = ({
  connections,
  updateSettings,
  closeParent,
  updateConnectionsData,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [connectionData, setConnectionData] = useState();

  useEffect(() => {
    if (connections) {
      setConnectionData(connections);
    }
  });

  const handeGoogleConnect = () => {
    setErrorMessage('');
    CalendarAPI.googleAuth().then((response) => {
      if (response?.data?.url) {
        window.open(response.data.url);
        closeParent();
      } else {
        setErrorMessage(response?.data?.detail);
      }
    });
  };

  const handeOutlookConnect = () => {
    setErrorMessage('');
    CalendarAPI.outlookAuth().then((response) => {
      if (response?.data?.url) {
        window.open(response.data.url);
        closeParent();
      } else {
        setErrorMessage(response?.data?.detail);
      }
    });
  };

  const handleActions = (connection, settingName) => {
    let updatedSetting = { ...connection };
    updatedSetting[settingName] = !connection[settingName];
    let connectionState = [...connectionData];
    let foundIndex = connectionState.findIndex((item) => item.email == connection.email);
    connectionState[foundIndex] = updatedSetting;
    updateSettings(updatedSetting);
    setConnectionData(connectionState);
  };

  const disconnect = (name) => {
    const email = null
    const connection = connectionData.find((conn) => conn.email === email);
    setErrorMessage('');
    const disconnectFrom = name.includes('outlook') ? 'outlook' : 'google';
    CalendarAPI.disconnect([disconnectFrom]).then((response) => {
      if (response) {
        updateConnectionsData(name);
      }
    });
  };

  return (
    <>
      <Grid container>
        <Grid container className={styles.connectCalendarBtnContainer}>
          <Grid item xs={4}>
            <Typography sx={{ p: 1 }} className={styles.connectCalendarText}>
              Connected Calendars
            </Typography>
          </Grid>
          <Grid item xs={2} className={styles.connectCalendarGrid}>
            <Button className={styles.connectGoogleBtn} onClick={handeGoogleConnect}>
              Connect Google Calendar
            </Button>
          </Grid>
          {/* <Grid item xs={0.6}></Grid>
          <Grid item xs={3} className={styles.connectCalendarGrid}>
            <Button className={styles.connectOutlookBtn} onClick={handeOutlookConnect}>
              Connect Outlook
            </Button>
          </Grid> */}
        </Grid>
        <Grid container>
          <Grid item xs={0.4}></Grid>
          <Grid item xs={10}>
            <Typography sx={{ p: 1 }} style={{ color: 'red' }}>
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeader}>Email</TableCell>
                {/* <TableCell className={styles.tableHeader}>Save actions to</TableCell> */}
                <TableCell className={styles.tableHeader}>Display in calendar</TableCell>
                {/* <TableCell className={styles.tableHeader}>Color</TableCell> */}
                <TableCell className={styles.tableHeader}>Disconnect</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectionData?.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {row.email}
                  </TableCell>
                  {/* <TableCell>
                    <ButtonGroup
                      variant='contained'
                      aria-label='Off or On'
                      className={styles.repeatBtnGroup}
                    >
                      <Button
                        onClick={() => handleActions(row, 'save_actions_to')}
                        className={`${!row.save_actions_to ? styles.selectedAction : ''}`}
                      >
                        Off
                      </Button>
                      <Button
                        onClick={() => handleActions(row, 'save_actions_to')}
                        className={`${row.save_actions_to ? styles.selectedAction : ''}`}
                      >
                        On
                      </Button>
                    </ButtonGroup>
                  </TableCell> */}
                  <TableCell>
                    <ButtonGroup
                      variant='contained'
                      aria-label='Off or On'
                      className={styles.repeatBtnGroup}
                    >
                      <Button
                        onClick={() => handleActions(row, 'display')}
                        className={`${!row.display ? styles.selectedAction : ''}`}
                      >
                        Off
                      </Button>
                      <Button
                        onClick={() => handleActions(row, 'display')}
                        className={`${row.display ? styles.selectedAction : ''}`}
                      >
                        On
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                  {/* <TableCell>
                    <CircleIcon sx={{ color: row.color, fontSize: '16px', padding: '0px' }} />
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      aria-label='Action status - new'
                      sx={{ padding: '0px', marginLeft: "24px" }}
                      onClick={() => disconnect(row.email)}
                    >
                      <DeleteIcon sx={{ color: '#000', fontSize: '16px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.calendarWarningTextContainer}>
          <Typography className={styles.calendarWarningText}>
            Wayy will create a new calendar for the google account that you connect with and save your Wayy actions there.
          </Typography>
          <Typography className={styles.calendarWarningText}>
            Wayy will display the events from your primary calendar of that google account in the Wayy calendar.
          </Typography>
          <Typography className={styles.calendarWarningText}>
            Please don&apos;t edit the Wayy events from within google calendar or delete the created Wayy calendar to avoid synchronisation issues.
          </Typography>
        </div>
      </Grid>
    </>
  );
};
export { ConnectExternalCalendar };
