import { configureStore } from '@reduxjs/toolkit';
import goalReducer from '../pages/myGoals/state/goalSlice';
import queueReducer from '../components/queue/state/queueSlice';
import headerReducer from '../components/header/state/headerSlice';
import { queueApi } from '../services/queue/queueAPI';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createReducer } from 'redux-orm';
import currentGoalColorReducer from './reducers/currentGoalColorSlice';
import orm from './models';

export const store = configureStore({
  reducer: {
    goals: goalReducer,
    queueItems: queueReducer,
    header: headerReducer,
    currentGoalColor: currentGoalColorReducer,
    [queueApi.reducerPath]: queueApi.reducer,
    orm: createReducer(orm),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(queueApi.middleware),
});

setupListeners(store.dispatch);
