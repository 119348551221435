import styles from './styles.module.scss';
import { Grid } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../../contexts/appContext';
import useForceUpdate from '../../../hooks/plantree/useForceUpdate';
import { actionsSelector, eventsSelector } from '../../../redux/selectors';
import { ActionAPI } from '../../../services/actions/actionService';
import { QueueAPI } from '../../../services/queue/queueService';
import ActionContextMenu from '../contextMenu/ActionContextMenu';
import { setEvents } from '../../../redux/models/event';
import { addQueueItem, deleteQueueItem } from '../../queue/state/queueSlice';


const SubgoalActionsListElement = ({
  actionId,
  index,
  editActionId,
  setEditActionId,
  input,
  setInput,
  setDeleteActionId,
  setDeleteActionName,
  setIsOpenConfirmationPopup,
  setSelectedAction,
  setIsSchedulePopupOpen,
  setStartDragging,
  setCurrentEvent,
  setCurrentAction,
  id,
  deleteAction,
  duplicateActions,
  handleInputChange,
  handleKeyDown,
  handleSaveAction,
  toggleAchievedAction,
  saveAction,
}) => {
  const dispatch = useDispatch();
  const [visibleMoreOption, setVisibleMoreOption] = useState('');
  const forceUpdate = useForceUpdate();
  const events = useSelector((state) => eventsSelector(state));
  const { dragStart, dragEnd } = useContext(AppContext);
  const { queueItems } = useSelector((state) => state.queueItems);
  const { color } = useSelector((state) => state.currentGoalColor);
  const isScheduleInCalendar = (id) => {
    return events.some(({ actionId }) => actionId === id);
  };

  const handleEditAction = ({ id, name }) => {
    setEditActionId(id);
    setInput(name);

    setTimeout(() => {
      document.getElementById(id)?.select();
    });
  };

  const handleDeleteAction = ({ id, name }) => {
    if (isScheduleInCalendar(id)) {
      setDeleteActionId(id);
      setDeleteActionName(name);
    } else {
      deleteAction(id, name);
    }
  };
  const handleDuplicateAction = (action) => {
    duplicateActions(action);
  };
  const handleRemoveFromQueue = (action) => {
    const actionIds = { actionIds: [action.id] };
    dispatch(deleteQueueItem(action?.id));
    QueueAPI.delete(actionIds)
      .then(() => { })
      .catch(() => {
        dispatch(addQueueItem(action));
      });
  };

  const saveQueueAction = (position, actionIds, action) => {
    dispatch(addQueueItem(action?.id));
    QueueAPI.create(position, actionIds).catch(() => {
      dispatch(deleteQueueItem(action?.id));
    });
  };

  const handleMoveToQueue = (action) => {
    const actionIds = [action.id];
    const prevEvents = [...events];
    const updatedEvents = prevEvents.filter((event) => {
      return event.actionId !== action.id;
    });
    dispatch(setEvents([...updatedEvents]));
    ActionAPI.deleteFromCalendar(action.id).catch(() => {
      dispatch(setEvents([...prevEvents]));
    });
    saveQueueAction('', actionIds, action);
    forceUpdate();
  };

  const deleteActionFromCalendar = (actionId) => {
    const prevEvents = [...events];
    const updatedEvents = prevEvents.filter((event) => {
      return event.actionId !== actionId;
    });
    dispatch(setEvents([...updatedEvents]));
    ActionAPI.deleteFromCalendar(actionId).catch(() => {
      dispatch(setEvents([...prevEvents]));
    });
  };

  const handleKeyPress = (e, action) => {
    if (editActionId === action.id) return;
    e.preventDefault();
    if (e.key === 'q' || e.key === 'Q') {
      queueItems.some((item) => item === action.id)
        ? handleRemoveFromQueue(action)
        : handleMoveToQueue(action);
    } else if (e.key === 'Backspace') {
      deleteAction(action.id, action.name);
    } else if (e.code == 'Space' && e.key == ' ') {
      event.target.select();
      handleEditAction({ id: action.id, name: action.name });
    } else if (e.key === 'd' || e.key === 'D') {
      handleDuplicateAction(action);
    } else if (e.key === 'a' || e.key === 'A') {
      if (action?.repetitive && !action.completed) {
        setIsOpenConfirmationPopup(true);
        setSelectedAction(action);
      } else {
        toggleAchievedAction(action);
      }
    } else if (e.key === 's' || e.key === 'S') {
      setIsSchedulePopupOpen(true);
    }
  };

  const handleFocus = () => { };

  const handleMouseOver = (e, value) => {
    if (visibleMoreOption !== value) setVisibleMoreOption(value);
  };
  const handleMouseOut = () => {
    setVisibleMoreOption('');
  };

  const handleSaveNote = (note, actionData) => {
    const obj = {
      name: actionData.name,
      id: actionData.id,
      notes: note,
    };
    saveAction(obj, actionData.id);
  };

  const handleActionClick = (e, action) => {
    setCurrentEvent(e.currentTarget);
    setCurrentAction(action);
    e.stopPropagation();
  };

  const handleOnDragStart = (e) => {
    dragStart(e);
    setStartDragging('');
  };

  const action = useSelector((state) => actionsSelector(state, actionId));
  const { name, parent_goal, notes, completed, repetitive } = action;
  const colorToRender = action.color ? action.color : color;

  return (
    <Grid container key={action.id} style={{ zIndex: 1000 }}>
      <Grid item md={11.9}>
        <div key={index} style={{ position: 'relative', width: '100%' }}>
          <FormControl
            className={`${styles.actionInputContainer} ${editActionId !== action.id ? styles.actionInputContainerReadOnly : ''
              } ${action.completed ? styles.actionInputCompleted : ''}`}
            key={index}
            onKeyDown={(e) => handleKeyPress(e, action)}
            onDragStart={handleOnDragStart}
            draggable={true}
            data-start={JSON.stringify({ goalId: id, ...action })}
            onMouseEnter={(e) => handleMouseOver(e, action.id)}
            onMouseLeave={handleMouseOut}
            onClick={(e) => handleActionClick(e, action)}
          >
            <OutlinedInput
              id={action.id}
              className={styles.actionAdornment}
              startAdornment=<InputAdornment position='start'>
                <IconButton
                  aria-label='Action status - new'
                  sx={{ padding: '0px' }}
                  onClick={() => {
                    toggleAchievedAction(action);
                  }}
                >
                  {action.completed ? (
                    <CheckCircleOutlineIcon sx={{ color: color, fontSize: '16px' }} />
                  ) : (
                    <CircleIcon sx={{ color: color, fontSize: '16px', padding: '0px' }} />
                  )}
                </IconButton>
              </InputAdornment>
              endAdornment={
                <InputAdornment position='end' style={{ marginRight: -5 }}>

                  <ActionContextMenu
                    actionObj={{
                      id: actionId,
                      name: name,
                      parent_goal: parent_goal,
                      notes: notes,
                      completed: completed,
                      color: colorToRender,
                      repetitive: repetitive,
                    }}
                    editAction={handleEditAction}
                    deleteAction={handleDeleteAction}
                    duplicateAction={handleDuplicateAction}
                    moveToQueueAction={handleMoveToQueue}
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    deleteActionFromCalendar={deleteActionFromCalendar}
                    handleSaveNote={handleSaveNote}
                    toggleAchievedAction={toggleAchievedAction}
                    editActionId={editActionId}
                    moreOption={visibleMoreOption}
                    showEventIcon={isScheduleInCalendar(action.id)}
                  />
                </InputAdornment>
              }
              size='small'
              value={editActionId === action.id ? input : action?.name}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleSaveAction}
              autoFocus={editActionId === action.id}
              onFocus={handleFocus}
            />
          </FormControl>
        </div>
      </Grid>
    </Grid>
  );
};

export default SubgoalActionsListElement;