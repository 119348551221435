import styles from './styles.module.scss';
import React, { memo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { constants } from './constants';
const DeleteActionDialog = ({ deleteActionId, actionName, setDeleteActionId, deleteAction }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [goalName, setGoalName] = useState('');

  useEffect(() => {
    if (deleteActionId) {
      setDialogOpen(true);
      setGoalName(actionName);
    } else {
      setDialogOpen(false);
      setGoalName('');
    }
  }, [deleteActionId]);

  const handleClose = () => {
    setDialogOpen(false);
    setDeleteActionId('');
  };

  const handleDeleteGoal = () => {
    setDialogOpen(false);
    setDeleteActionId('');
    deleteAction();
  };
  if (!dialogOpen) return null;
  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Grid container spacing={2} columns={{ xs: 4 }} style={{ minWidth: '450px' }}>
          <Grid item xs={12}>
            <DialogTitle id='alert-dialog-title' className={styles.title}>
              {constants.HEADING1} <b>{goalName}</b> {constants.HEADING2}
            </DialogTitle>
          </Grid>
        </Grid>

        <DialogActions className={styles.actionContainer}>
          <Grid container spacing={1}>
            <Grid item xs={0.75}></Grid>
            <Grid item xs={5}>
              <Button className={styles.goBack} onClick={handleClose}>
                {constants.GO_BACK}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button className={styles.delete} onClick={handleDeleteGoal}>
                {constants.UNSCHEDULE_DELETE}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(DeleteActionDialog);
