const constants = {
  MOVE_TO_QUEUE: 'Move to Queue',
  MOVE_TO_QUEUE_REPETITIVE: 'Stop repeating and move to Queue',
  DELETE: 'Delete',
  REMOVE_FROM_QUEUE: 'Remove from Queue',
  DELETE_COMPLETELY: 'Delete Completely',
  DELETE_FROM_CALENDAR: 'Delete from Calendar',
  SHIFT_DEL: '[SHIFT] + [DEL]',
  BACKSPACE: '[BACKSPACE]',
  Q_KEY: '[Q]',
  S_KEY: '[S]',
  N_KEY: '[N]',
  D_KEY: '[D]',
  A_KEY: '[A]',
  ACTION_COMPLETED: 'Completed',
  ACTION_UNCOMPLETE: 'Uncomplete',
  WRITE_NOTE: 'Write Notes',
  RENAME: 'Rename',
  SPACEBAR: '[SPACEBAR]',
  DUPLICATE: 'Duplicate',
  SHIFT_BACKSPACE: '[SHIFT] + [BACKSPACE]',
  SCHEDULE: 'Schedule',
  ACHIEVE_GOAL: 'Achieve Goal',
  ACHIEVE_SUBGOAL: 'Achieve subGoal',
  UNACHIEVE_GOAL: 'Un-achieve Goal',
  UNACHIEVE_SUBGOAL: 'Un-achieve subGoal',
  SEE_PLAN_TREE: 'See Plan Tree',
  SHIFT_ENTER: '[SHIFT + ENTER]',
  CHANGE_COLOR: 'Change colour',
  CONNECT_GOAL:
    'This Action is not connected to any Goal. To focus your efforts, make sure your Actions connect to long term goals.',
};

export { constants };
