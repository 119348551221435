const formatConnections = (connections) => {
  if (connections) {
    let connectionsArr = [];
    Object.entries(connections).map(([key, value]) => {
      const cns = { email: key, ...value };
      connectionsArr = [cns, ...connectionsArr];
    });
    return connectionsArr;
  }
  return null;
};

export default formatConnections;
