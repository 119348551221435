import React, { useState, useEffect, useContext } from 'react';
import { Handle, Position, useReactFlow, useKeyPress } from 'reactflow';
import cx from 'classnames';
import styles from './nodeTypes.module.scss';
import { GoalsContext } from '../../contexts/goalsContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SubgoalActionsList from './subgoalActionsList/SubgoalActionsList';
import useForceUpdate from '../../hooks/plantree/useForceUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { MyGoalsAPI } from '../../services/myGoals';
import Loader from '../loader/Loader';
import { ActionAPI } from '../../services/actions/actionService';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { setStatus } from '../header/state/headerSlice';
import Tooltip from '@mui/material/Tooltip';
import { uuid } from '../../utils/utils';
import { deleteQueueItem, addQueueItem } from '../queue/state/queueSlice';
import { subgoalActionsSelector, eventsSelector } from '../../redux/selectors';
import {
  updateAction,
  deleteAction,
  addAction,
  toggleAchievedAction,
} from '../../redux/models/action';
import { setEvents } from '../../redux/models/event';
import { AppContext } from '../../contexts/appContext';

const SubGoalNode = ({ id, data, selected }) => {
  const forceUpdate = useForceUpdate();
  const {
    onClickNode,
    openContextMenu,
    menuAction,
    setMenuAction,
    selectedGoalForMenu,
    setPanOnDrag,
    isLoadingGoal,
    canvasClicked,
    setCanvasClicked,
    goalsReorganize: { dragStart, dragEnter, drop, dragOver, dragEnd, dragLeave, dragItem },
  } = useContext(GoalsContext);
  const inputRef = React.createRef();
  const [label, setLabel] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [showList, setShowList] = useState(false);
  const { setNodes, getNodes } = useReactFlow();
  const { treeCreators } = useSelector((state) => state.header);
  const actions = useSelector((state) => subgoalActionsSelector(state, id));
  const events = useSelector((state) => eventsSelector(state));
  const { color } = useSelector((state) => state.currentGoalColor);
  const { setMessage, setIsOpenAlert } = useContext(AppContext);

  const dispatch = useDispatch();
  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    setLabel(data.label);
  }, [localStorage.getItem('selectedNodeId')]);

  const arrowPresses = useKeyPress(['Space']);

  useEffect(() => {
    if (!selected) {
      setShowList(false);
      setIsEditable(false);
    }
  }, [selected, setShowList, setIsEditable]);

  useEffect(() => {
    if (arrowPresses) {
      setLabel((label) => label);
      setIsEditable(selected);
      setTimeout(() => {
        document.getElementById(`input-goal`)?.select();
      });
    }
  }, [arrowPresses]);

  const honClick = () => {
    onClickNode(id);
  };

  const toggleCompleted = (e) => {
    e.stopPropagation();
    const nodes = getNodes();
    let isMainGoal;
    let label, completed;
    nodes.forEach((element) => {
      if (element.data.goalId === data.goalId) {
        label = element.data.label;
        completed = element.data.completed;
        element.data.completed = !element.data.completed;
        isMainGoal = element.data.mainGoal;
      }
    });
    setNodes([...nodes]);
    forceUpdate();

    if (isMainGoal) {
      setNodes([...nodes]);
      MyGoalsAPI.modify(data.goalId, { completed: !completed }).then(() => {});
    } else {
      setNodes([...nodes]);
      MyGoalsAPI.modifySubgoal(data.goalId, { name: label, completed: !completed }).then(() => {});
    }
  };

  const handleSaveAction = (input, editActionId) => {
    dispatch(setStatus('Saving'));
    if (editActionId) {
      dispatch(updateAction(input));
      updateActionData(input, editActionId);
    } else {
      const action = {
        parent_goal: id,
        name: input.name,
        id: input.id,
        color: color
      };
      dispatch(addAction({ ...action, id: input.id }));
      ActionAPI.create(action).then(() => {dispatch(setStatus('All saved'))});
    }
    forceUpdate();
  };

  const updateActionData = (input, editActionId) => {
    const inputData = { ...input };
    ActionAPI.modify(editActionId, inputData).then((response) => {
      if (response) {
        dispatch(setStatus('All saved'));
      }
    });
  };

  const handleDuplicateActions = (action) => {
    const newAction = {
      id: uuid(),
      parent_goal: id,
      name: action.name + ' copy',
    };
    dispatch(addAction(newAction));
    ActionAPI.create(newAction)
      .then(() => {})
      .catch(() => {
        dispatch(deleteAction(newAction.id));
      });
    forceUpdate();
  };

  const handleDeleteAction = (actionId, actionName) => {
    const action = actions[actionId];

    const prevEvents = [...events];
    const filteredEvents = prevEvents.filter((item) => item.actionId !== actionId);

    dispatch(setEvents(filteredEvents));
    dispatch(deleteAction(actionId));
    dispatch(deleteQueueItem(actionId));

    ActionAPI.delete(actionId)
      .then(() => {})
      .catch(() => {
        dispatch(addAction(action));
        dispatch(setEvents(prevEvents));
        dispatch(addQueueItem({ id: actionId, name: actionName, parent_goal: id }));
      });
  };

  const showActionList = () => {
    setShowList(!showList);
  };

  const handleMarkAchievedAction = (action) => {
    dispatch(toggleAchievedAction(action.id));
    dispatch(deleteQueueItem(action.id));

    ActionAPI.modify(action.id, { completed: !action.completed })
      .then(() => {})
      .catch(() => {
        dispatch(toggleAchievedAction(action.id));
        dispatch(addQueueItem(action));
      });
  };

  const actionsCount = Object.keys(actions).length;

  const getReadOnlyLabel = () => {
    const circleRoundedIconColor = data.mainGoal ? 'rgba(255, 255, 255, 0.4)' : color;
    return (
      <>
        <div className={`main-goal ${styles.mainGoalCont}`}>
          {data.completed ? (
            <CheckCircleOutlineIcon
              fontSize='small'
              style={{ position: 'absolute', left: '8px', color: color }}
              onClick={toggleCompleted}
            />
          ) : (
            <CircleRoundedIcon
              fontSize='small'
              style={{
                position: 'absolute',
                left: '8px',
                color: circleRoundedIconColor,
              }}
              onClick={toggleCompleted}
            />
          )}
          <span
            className={
              styles.goalLabel2 +
              ' ' +
              (data.mainGoal ? styles.mainGoalLabel : styles.subGoalLabel) +
              ' ' +
              (data.completed ? styles.goalCompletedLabel : '')
            }
          >
            {data.label.length > 70 ? (
              <Tooltip title={data.label} placement='top' arrow>
                <span>{data.label.substring(0, 70).concat(' ...')}</span>
              </Tooltip>
            ) : (
              data.label
            )}
          </span>

          {actionsCount ? (
            <div onClick={showActionList} className={styles.actionsCount}>
              {actionsCount}
            </div>
          ) : (
            <ArrowDropDown
              fontSize='small'
              style={{
                position: 'absolute',
                right: '7px',
                color: data.completed || !data.mainGoal ? '#000' : '#fff',
                display: selected ? 'block' : 'none',
                transform: showList ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
              className='addIcon'
              onClick={showActionList}
            />
          )}
        </div>
        {showList ? (
          <div className={data.mainGoal || !!data.label ? 'listView' : 'listView withTop'}>
            
            <SubgoalActionsList
              actions={actions.filter((action) => action.parent_goal === id)}
              saveAction={handleSaveAction}
              duplicateActions={handleDuplicateActions}
              deleteAction={handleDeleteAction}
              toggleAchievedAction={handleMarkAchievedAction}
              id={id}
              setShowList={setShowList}
            />
           
          </div>
          // </Draggable>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handleLabelBlur = () => {
    if (data.mainGoal) {
      const nodes = getNodes();
      nodes.forEach((element) => {
        if (element.id === id) {
          element.data.label = label;
        }
      });
      setNodes([...nodes]);
      setIsEditable(false);
      setMenuAction('');
      MyGoalsAPI.modify(id, { name: label }).then(() => {});
    } else {
      const isTempId = treeCreators.some(
        (tc) => tc.status === 'pending' && tc.tempSubGoalId === id,
      );
      if (isTempId) {
        const nodes = getNodes();
        nodes.forEach((element) => {
          if (element.id === id) {
            element.data.label = label;
          }
        });
        setNodes([...nodes]);
        setIsEditable(false);
        setMenuAction('');
      } else {
        const nodes = getNodes();
        nodes.forEach((element) => {
          if (element.id === id) {
            element.data.label = label;
          }
        });
        setNodes([...nodes]);
        setIsEditable(false);
        setMenuAction('');
        MyGoalsAPI.modifySubgoal(id, { name: label })
        .then(() => {
        })
        .catch(error => {
          setMessage("Something went wrong with saving the subgoal, please refresh and try again")
        });

      }
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLabelBlur();
      e.preventDefault();
    } else if (e.key === 'Tab') {
      //handleLabelBlur()
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (menuAction === 'rename') {
      setIsEditable(selected);
      setTimeout(() => {
        document.getElementById(`input-goal`)?.select();
      }, 100);
    }
  }, [menuAction]);

  const handleOutsideClick = (e) => {
    if (isEditable && !e.target.closest('.main-goal-edit')) {
      handleLabelBlur();
      setIsEditable(false);
    }
  };

  const handleOutsideClickWithoutEvent = () => {
      handleLabelBlur();
      setIsEditable(false);
  };

  useEffect(() => {

    // attach listener on mount
    document.addEventListener('mousedown', handleOutsideClick);
  
    // detach listeners on un-mount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isEditable, handleLabelBlur]);

  useEffect(() => {
    if (canvasClicked) {
      
      handleOutsideClickWithoutEvent();
      // reset the state to false after you handled the canvas click
      setCanvasClicked(false);
    }
  }, [canvasClicked]);

  const getEditableLabel = () => {
    return (
      <div className='main-goal-edit'>
        <input
          onKeyDown={_handleKeyDown}
          tabIndex='-1'
          ref={inputRef}
          type={'text'}
          value={label}
          onChange={handleLabelChange}
          // onBlur={handleLabelBlur}
          className={
            data.mainGoal 
              ? (data.completed ? 'inputNodeMainGoalCompleted' : 'inputNodeMainGoalNotCompleted')
              : 'inputNodeSubGoal'
          }          
          autoFocus
          style={{ background: '#fff' }}
          id={`input-goal`}
        />
      </div>
    );
};
  const handleOpenContextMenu = (e) => {
    e.stopPropagation();
    openContextMenu(data.goalId, setIsEditable);
  };

  const isTempEditable = menuAction === 'rename' && data.goalId == selectedGoalForMenu;

  const constructNodeStyle = () => {
    let nodeStyle = {};

    if (dragItem === data.goalId) {
      nodeStyle.opacity = '0.6';
    } else {
      if (dragItem) {
        nodeStyle.pointerEvents = 'none';
      }
    }

    if (data.mainGoal) {
      nodeStyle.background = color;
    }

    return nodeStyle;
  };

  const nodeStyle = constructNodeStyle();
  const visibility = selected ? "visible" : "hidden";

  return (
    <div
      onDragEnter={dragEnter}
      onDragOver={dragOver}
      onDrop={drop}
      onDragLeave={dragLeave}
      data-target={data.goalId}
      className={styles.goalNodeContainerBox}
    >
      <div
        onClick={honClick}
        onDoubleClick={() => setShowList(true)}
        className={cx(
          data.mainGoal ? styles.node + ' ' + styles.mainGoalNode : styles.childNode,
          selected ? 'selected-node' : '',
          data.completed ? styles.goalCompleted : '',
          data?.label.length > 32 ? styles.goalBigLabel : '',
        )}
        id={'context-source' + data.goalId}
        onMouseEnter={() => setPanOnDrag(false)}
        onMouseLeave={() => setPanOnDrag(true)}
        draggable={!showList}
        onDragLeave={dragLeave}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        data-source={data.goalId}
        style={nodeStyle}
      >
        <Loader
          open={isLoadingGoal && localStorage.getItem('selectedNodeId') === data.goalId}
          noBackground={true}
        />

        {isEditable || isTempEditable ? getEditableLabel() : getReadOnlyLabel()}

        <Handle
          className={styles.handle}
          type='target'
          position={Position.Top}
          isConnectable={false}
        />
        <Handle
          className={styles.handle}
          type='source'
          position={Position.Bottom}
          isConnectable={false}
        />
      </div>
      <div style={{ display: 'flex', visibility: visibility}}>
          <img
            src='/images/icons/more-horiz.svg'
            alt='more'
            onClick={handleOpenContextMenu}
            className={data.mainGoal || !!data.label ? 'contextIcon' : 'contextIcon withTop'}
          />
      </div>
    </div>
  );
};

export default SubGoalNode;
