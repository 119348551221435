import React, { memo, useState, useContext } from 'react';
import styles from './contextMenu.module.scss';
import ColorPicker from '../colorPicker/ColorPicker';
import { Divider } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { GoalsContext } from '../../../contexts/goalsContext';
import { useReactFlow } from 'reactflow';
import { constants } from './constants';
import { MyGoalsAPI } from '../../../services/myGoals';
import { useNavigate } from 'react-router-dom';

const ContextMenu = ({ hidePlanTree, hideColorChange, mainGoal = false }) => {
  const { clickContextMenu, setMenuAction, selectedGoalForMenu } = useContext(GoalsContext);
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const { setNodes, getNodes } = useReactFlow();
  const navigate = useNavigate();

  const handleClickContextMenu = (e, action, id) => {
    e.stopPropagation();
    if (action === 'colorChange') {
      setMenuAction(action);
      setIsOpenPicker((isOpenPicker) => !isOpenPicker);
      return;
    } else if (action === 'markDone') {
      const nodes = getNodes();
      let completed;
      let isMainGoal;
      nodes.forEach((element) => {
        if (element.id === selectedGoalForMenu) {
          completed = element.data.completed;
          element.data.completed = !element.data.completed;
          isMainGoal = element.data.mainGoal;
        }
      });
      if (isMainGoal || mainGoal) {
        MyGoalsAPI.modify(selectedGoalForMenu, { completed: !completed }).then(() => {
          setNodes([...nodes]);
        });
      } else {
        MyGoalsAPI.modifySubgoal(selectedGoalForMenu, { completed: !completed }).then(
          () => {
            setNodes([...nodes]);
          },
        );
      }
    } else if (action === 'seePlanTree') {
      navigate(`/goals/${id}`);
    } else if (action === 'mainGoals') {
      navigate(`/goals`);
    }
    setIsOpenPicker(false);
    clickContextMenu(action);
  };

  const isGoalCompleted = () => {
    const nodes = getNodes();
    return nodes.some((node) => node.id === selectedGoalForMenu && node.data.completed);
  };

  const selectedGoal = getNodes().find((node) => node.id === selectedGoalForMenu);
  const isMainGoal = selectedGoal ? selectedGoal.data.mainGoal : false;

  return (
    <div className={styles.contextMenuContainer} id='context-menu'>
      <div
        className={`${styles.menu} ${styles.primaryActionBackgroundItem}`}
        onClick={(e) => handleClickContextMenu(e, 'markDone')}
      >
        <div className={styles.menuItem}>
          <span
            className={styles.menuTextNormal}
          >
            {
              (isMainGoal || mainGoal)
                ? (isGoalCompleted() ? constants.UNACHIEVE_GOAL : constants.ACHIEVE_GOAL)
                : (isGoalCompleted() ? constants.UNACHIEVE_SUBGOAL : constants.ACHIEVE_SUBGOAL)
            }

          </span>
          <span className={styles.menuTextGrey}>{constants.A_KEY}</span>
        </div>
        {/* {isGoalCompleted() ? (
          <span className={styles.menuIcon} style={{ color: '#000' }}>
            <DoneIcon />
          </span>
        ) : (
          <span className={styles.menuIcon} style={{ color: '#fff' }}>
            <CircleOutlinedIcon />
          </span>
        )} */}
      </div>
      {!hidePlanTree && (
        <>
          <div className={styles.divider}>
            <Divider />
          </div>
          <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'seePlanTree', selectedGoal.id)}>
            <div className={styles.menuItem}>
              <span className={styles.menuTextNormal}>{constants.SEE_PLAN_TREE}</span>
              <span className={styles.menuTextGrey}>{constants.SHIFT_ENTER}</span>
            </div>
          </div>
          <div className={styles.divider}>
            <Divider />
          </div>
        </>
      )}
      <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'rename')}>
        <div className={styles.menuItem}>
          <span className={styles.menuTextNormal}>{constants.RENAME}</span>
          <span className={styles.menuTextGrey}>{constants.SPACEBAR}</span>
        </div>
      </div>
      {!hideColorChange ? (
        <>
          <div
            className={styles.menu + ' ' + styles.colorChange}
            onClick={(e) => handleClickContextMenu(e, 'colorChange')}
          >
            <div className={styles.menuItem}>
              <span className={styles.menuTextNormal}>{constants.CHANGE_COLOR}</span>
            </div>
            <span className={styles.menuIcon}>
              <ChevronRightOutlinedIcon />
            </span>
            {isOpenPicker && <ColorPicker />}
          </div>
          <div className={styles.divider}>
            <Divider />
          </div>
        </>
      ) : (
        isMainGoal && (
          <>
            <div className={styles.divider}>
              <Divider />
            </div>
            <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'mainGoals')}>
              <div className={styles.menuItem}>
                <span className={styles.menuTextNormal}>More options on the main goals screen</span>
              </div>
            </div>
          </>
        )
      )}

      {!isMainGoal && (
        <div className={styles.menu} onClick={(e) => handleClickContextMenu(e, 'delete')}>
          <div className={styles.menuItem}>
            <span className={styles.menuTextNormal}>{constants.DELETE}</span>
            <span className={styles.menuTextGrey}>{constants.BACKSPACE}</span>
          </div>
        </div>
      )}

    </div>
  );
};

export default memo(ContextMenu);
