import { api } from '../configs/axiosConfig';
import { defineCancelApiObject } from '../configs/axiosUtils';

export const QueueAPI = {
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: '/api/queue/',
      method: 'GET',
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  create: async function (position, actionIds, cancel = false) {
    let url = '';
    if (position !== null && position !== undefined) {
      url = `/api/queue/?position=${position}`;
    } else {
      url = `/api/queue/`;
    }
    const response = await api.request({
      url: url,
      method: 'POST',
      data: { actionIds },
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  modify: async function (queueObj, cancel = false) {
    const response = await api.request({
      url: `/api/queue/`,
      method: 'PATCH',
      data: queueObj,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (actionsIds, cancel = false) {
    const response = await api.request({
      url: `/api/queue/`,
      method: 'DELETE',
      data: actionsIds,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(QueueAPI);
