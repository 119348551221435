import React, { useState, useContext, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uuid } from '../../utils/utils';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import styles from './styles.module.scss';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ActionContextMenu from '../nodeTypes/contextMenu/ActionContextMenu';
import { ActionAPI } from '../../services/actions/actionService';
import { AppContext } from '../../contexts/appContext';
import AddIcon from '@mui/icons-material/Add';
import ScheduleActionPopup from '../nodeTypes/contextMenu/ScheduleActionPopup';
import { actionsSelector } from '../../redux/selectors';
import { toggleAchievedAction, updateAction, addAction } from '../../redux/models/action';
import { deleteQueueItem, addQueueItem } from '../queue/state/queueSlice';
import { setStatus } from '../header/state/headerSlice';
import { Draggable } from 'devextreme-react';

const QueueList = ({
  mainQueueItems,
  overflowQueueItems,
  handleDeleteAction,
  removeFromQueue,
  saveQueueitem,
  isNewInput,
  isoverFlowInput,
  addOverflowQueuItem,
  openOverFlowItem,
  handleOnBlur,
  actions
}) => {
  const { dragEnter, dragEnd, dragStart, currentDraggedActionId, draggedActionForReordering } =
    useContext(AppContext);
  const fAction = useRef(null);
  const ref = useRef(null);
  const itemsRef = useRef([]);
  const [input, setInput] = useState();
  const label = 'floating action';
  const [currentEvent, setCurrentEvent] = useState();
  const [currentAction, setCurrentAction] = useState();
  const [visibleMoreOption, setVisibleMoreOption] = useState('');
  const [isSchedulePopupOpen, setIsSchedulePopupOpen] = useState(false);
  const [editActionId, setEditActionId] = useState();
  const allActions = useSelector((state) => actionsSelector(state));
  const mainQueueActions = mainQueueItems.map((itemId) => allActions.find((action) => action.id === itemId))
  .filter(Boolean);
  const overflowQueueActions = overflowQueueItems.map((itemId) => allActions.find((action) => action.id === itemId))
  .filter(Boolean);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const editActionIdRef = useRef();

  useEffect(() => {
    inputRef.current = input;
  }, [input]);

  useEffect(() => {
    editActionIdRef.current = editActionId;
  }, [editActionId]);

  useEffect(() => {
    setInput(label);
  }, [isNewInput]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (itemsRef.current[0] && !itemsRef.current[0].contains(event.target)) {
        if (inputRef.current !== '') {
          handleSaveAction(inputRef.current, editActionIdRef.current);
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const deleteAction = (action) => {
    handleDeleteAction(action);
  };
  const handleRemoveFromQueue = (action) => {
    removeFromQueue(action);
  };

  const handleQueueItemClick = (e, data) => {
    setCurrentEvent(e.currentTarget);
    setCurrentAction(data);
    e.stopPropagation();
  };

  const handleKeyPress = (evt, data) => {
    if (editActionId === data.id) return;
    evt.preventDefault();
    if (evt.shiftKey && evt.key == 'Backspace') {
      handleDeleteAction(data);
    } else if (evt.key === 'Backspace') {
      removeFromQueue(data);
    } else if (evt.key === 's' || evt.key === 'S') {
      setIsSchedulePopupOpen(true);
    } else if (evt.code == 'Space' && evt.key == ' ') {
      handleRenameAction(data);
    } else if (evt.key === 'a') {
      handleToggleAchievedAction(data);
    }
  };

  const handleToggleAchievedAction = (action) => {
    const completed = action.completed;

    if (!completed) {
      dispatch(deleteQueueItem(action.id));
    }
    dispatch(toggleAchievedAction(action.id));

    ActionAPI.modify(action.id, { completed: !completed })
      .then(() => {})
      .catch(() => {
        if (!completed) {
          dispatch(addQueueItem(action));
        }
        dispatch(toggleAchievedAction(action.id));
      });
  };

  const handleSaveNote = (note, action) => {
    dispatch(setStatus("Saving"));
    dispatch(updateAction({...action, notes: note}));
    ActionAPI.modify(action.id, {notes: note}).then((response) => {
      if (response) {
        dispatch(setStatus('All saved'));
      }
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSaveAction();
    }
  };

  const handleSaveAction = (inputValue, editActionIdValue) => {
    let valueToUse = input || inputValue;
    let editActionIdValueToUse = editActionId || editActionIdValue;
    if (!valueToUse) return;
    
    if (editActionIdValueToUse) {
      dispatch(setStatus('Saving'));
      dispatch(updateAction({ name: valueToUse, id: editActionIdValueToUse }));
      ActionAPI.modify(editActionIdValueToUse, {name: valueToUse}).then(() => {
        dispatch(setStatus('All saved'));
      }).catch((error) => {
        console.log("error")
      })
    } 
    // else {
    //   saveQueueitem(valueToUse);
    // }
    setInput('');
    setEditActionId('');
  };

  const handleFocus = (event) => event.target.select();
  const addqueueItem = (e) => {
    e.stopPropagation();
    e.preventDefault();
    addOverflowQueuItem(e);
  };
  const handleMouseOver = (e, value) => {
    if (visibleMoreOption !== value) setVisibleMoreOption(value);
  };
  const handleMouseOut = () => {
    setVisibleMoreOption('');
  };

  const handleSchedulePopupOpen = () => {
    setIsSchedulePopupOpen(false);
  };

  const handleRenameAction = ({ id, name }) => {
    setEditActionId(id);
    setInput(name);
    setTimeout(() => {
      const index = mainQueueActions.findIndex((action) => action.id === id);
      itemsRef.current[index].click();
    }, 100);
    setTimeout(() => {
      document.getElementById("queue-item" + id)?.getElementsByTagName("input")[0].select();
    });
  }

  const handleDuplicateAction = (action) => {
    const newAction = {
      ...action,
      id: uuid(),
      name: action.name + ' copy',
    };
    dispatch(addAction(newAction));
    dispatch(addQueueItem(newAction.id));

    ActionAPI.create(newAction, false, true)
      .then(() => {})
      .catch(() => {
        dispatch(deleteQueueItem(newAction.id))
        dispatch(deleteAction(newAction.id));
      });
  };

  return (
    <>
      <div className={styles.queueItems} data-component={"queueItem"}>
        {mainQueueActions?.map((action, index) => {
          return (
            <Draggable
            clone
            style={{marginRight: "4px", marginLeft: "4px"}}
            key={action.id}
            onDragEnd={dragEnd}
            onDragStart={(e) => dragStart(e, 'inqueue')}
            data={actions.find((a) => a.id === action.id)}
            group="calendarLayoutGroup"
            id={"queue-item" + action.id}
            onDragEnter={(e) => dragEnter(e, action.id, index)}
            >

            <div  
              data-component={"queueItem"}
              className={`
                ${styles.normalContainer} 
                ${index == 0 ? styles.firstElementOfQueue : ''}
              `}
            >
              <FormControl
                className={`${styles.actionInputContainer} 
                ${editActionId !== action.id ? styles.actionInputContainerReadOnly : ''}
                ${action.id === draggedActionForReordering?.id ? styles.currentlyDraggedQueueItem : ''}`
                }
                key={index}
                onKeyDown={(e) => handleKeyPress(e, action)}
                onClick={(e) => handleQueueItemClick(e, action)}
                style={{opacity: draggedActionForReordering?.id === action.id ? 0 : 1}}
                onMouseEnter={(e) => handleMouseOver(e, action.id)}
                onMouseLeave={handleMouseOut}
                id={'queue-area'}
                data-end={'grid'}
                data-start={JSON.stringify({ ...action })}
                data-component={"queueItem"}
            
              >
                <OutlinedInput
                style={{
                  pointerEvents: editActionId === action.id ? 'auto' : 'none',
                  opacity: currentDraggedActionId === action.id ? 0.6 : 1,
                }}
                  id="outlined-adornment-action"
                  data-component={"queueItem"}
                  className={styles.queueItem}
              
                  startAdornment=<InputAdornment position="start" className={styles.firstInputAdornment}>
                    <CircleIcon sx={{ color: action.color, fontSize: "20px" }} />
                  </InputAdornment>
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ backgroundColor: 'transparent', pointerEvents: "auto" }}
                    >
                      <ActionContextMenu
                        actionObj={action}
                        toggleAchievedAction={handleToggleAchievedAction}
                        handleSaveNote={handleSaveNote}
                        deleteAction={deleteAction}
                        duplicateAction={handleDuplicateAction}
                        editAction={handleRenameAction}
                        editActionId={editActionId}
                        handleRemoveFromQueue={handleRemoveFromQueue}
                        isQueueAction={true}
                        moreOption={visibleMoreOption}
                      />
                    </InputAdornment>
                  }
                  size='small'
                  value={editActionId === action.id ? input : action.name}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  ref={(el) => (itemsRef.current[index] = el)}
                  autoFocus={editActionId === action.id}
                />
              </FormControl>
            </div>
            </Draggable>
          );
        })}
        {isNewInput && (
          <TextField
            className={`${styles.queueInputControl}`}
            onChange={handleInputChange}
            value={input}
            onKeyDown={handleKeyDown}
            onBlur={handleSaveAction}
            ref={fAction}
            id='floatingAction'
            autoFocus={true}
            onFocus={handleFocus}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CircleIcon sx={{ color: '#000', fontSize: '16px' }} />
                </InputAdornment>
              ),
            }}
          ></TextField>
        )}
      </div>
      {openOverFlowItem && overflowQueueItems.length > 0 && (
        <div className={styles.queueOverflow} ref={ref}>
          <div className={styles.addIcon}>
            <IconButton aria-label='Action status - new' onClick={(e) => addqueueItem(e)}>
              {<AddIcon sx={{ color: '#000', fontSize: '30px' }} />}
            </IconButton>
          </div>
          <div className={styles.queueOverFlowItems} data-component={"queueItem"}>
            {overflowQueueActions?.map((action, index) => {
              return (
                <div
                  key={index}
                  data-component={"queueItem"}
                  onDragEnter={(e) => dragEnter(e, action.id, overflowQueueActions.length-index+5)}
                  className={styles.normalContainerOverFlow}
                >
                  <FormControl
                    className={`${styles.actionInputContainerOverFlow} ${styles.actionInputContainerReadOnlyOverFlow}`}
                    key={index}
                    onKeyDown={(e) => handleKeyPress(e, action)}
                    onClick={(e) => handleQueueItemClick(e, action)}
                    onDragEnd={dragEnd}
                    onDragStart={(e) => dragStart(e, 'inqueue')}
                    style={{opacity: currentDraggedActionId === action.id ? 0.6 : 1}}
                    // draggable
                    id={'queue-area'}
                    data-end={'grid'}
                    data-start={JSON.stringify({ ...action })}
                    data-component={"queueItem"}
                  >
                    <OutlinedInput
                      id='outlined-adornment-action'
                      data-component={"queueItem"}
                      startAdornment=<InputAdornment position='start'>
                        <CircleIcon sx={{ color: action.color, fontSize: "16px" }} />
                      </InputAdornment>
                      endAdornment={
                        <InputAdornment
                          position='end'
                          style={{
                            marginRight: -5,
                            backgroundColor: 'transparent',
                            marginTop: 14,
                          }}
                        >
                          <ActionContextMenu
                            actionObj={action}
                            toggleAchievedAction={handleToggleAchievedAction}
                            handleSaveNote={handleSaveNote}
                            deleteAction={deleteAction}
                            duplicateAction={handleDuplicateAction}
                            editAction={handleRenameAction}
                            editActionId={editActionId}
                            handleRemoveFromQueue={handleRemoveFromQueue}
                            isQueueAction={true}
                            moreOption={action.id}
                            overFlowAction={true}
                          />
                        </InputAdornment>
                      }
                      size='small'
                      value={action?.name}
                    />
                  </FormControl>
                </div>
              );
            })}
          </div>
          {isoverFlowInput && (
            <div className={styles.queueInputOverFlowControlContainer}>
              <TextField
                className={`${styles.queueInputOverFlowControl}`}
                onChange={handleInputChange}
                value={input}
                onKeyDown={handleKeyDown}
                onBlur={handleSaveAction}
                ref={fAction}
                id='floatingAction'
                autoFocus={true}
                onFocus={handleFocus}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <CircleIcon sx={{ color: '#000', fontSize: '16px' }} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
          )}
        </div>
      )}
      <ScheduleActionPopup
        isQueueAction={true}
        action={currentAction}
        fromCalendar={false}
        isOutsideContextMenu={true}
        currentEvent={currentEvent}
        isOverflow={openOverFlowItem}
        isSchedulePopupOpen={isSchedulePopupOpen}
        handleSchedulePopupOpen={handleSchedulePopupOpen}
      />
    </>
  );
};

export default QueueList;
