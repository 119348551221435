import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  queueItems: [],
};

export const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    addQueueItem: (state, action) => {
      // I think this method is broken
      state.queueItems = [action.payload, ...state.queueItems];
    },
    setQueueItems: (state, action) => {
      state.queueItems = [...action.payload];
    },
    getQueueItems: (state) => state.queueItems,
    deleteQueueItem: (state, action) => {
      state.queueItems = state.queueItems.filter((item) => item !== action.payload);
    },
  },
});

export const { addQueueItem, getQueueItems, deleteQueueItem, setQueueItems } =
  queueSlice.actions;

export default queueSlice.reducer;
