import React, { useRef, useState, useContext, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import styles from './contextMenu.module.scss';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { constants } from './constants';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { timeSlots, intervals, timePeriods } from '../../../constants/scheduler';
import { dateCreator, generateSchedulePayload, formatDate, getDefaultStartTime } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { deleteQueueItem } from '../../queue/state/queueSlice';
import { AppContext } from '../../../contexts/appContext';
import { ActionAPI } from '../../../services/actions/actionService';
import { uuid } from '../../../utils/utils';
import { eventsSelector, actionsSelector } from '../../../redux/selectors';
import { deleteEvent, setEvents } from '../../../redux/models/event';
import { updateAction } from '../../../redux/models/action';
import { EventsContext } from '../../../contexts/eventsContext';

export default function ScheduleActionPopup({
  action = {},
  handleParentClose,
  isQueueAction,
  fromCalendar,
  isOutsideContextMenu,
  currentEvent,
  isOverflow,
  deleteFromCalendar,
  isSchedulePopupOpen,
  handleSchedulePopupOpen,
}) {
  const {
    setIsOpenAlert,
    setMessage,
    selectedWeek,
    retrieveEvents,
  } = useContext(AppContext);
  const isVariant = true;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(dayjs(new Date()));
  const [checked, setChecked] = useState(false);
  const [intervalType, setIntervalType] = useState('One time');
  const [from, setFrom] = useState(getDefaultStartTime());
  const [to, setTo] = useState(getDefaultStartTime(1));
  const [iskeyStroke, setIskeyStroke] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [interval, setInterval] = useState(1);
  const [time_period, setTimePeriod] = useState('Day');
  const [endRepeat, setEndRepeat] = useState('Never');
  const [endRepeatDate, setEndRepeatDate] = useState(dayjs(new Date()));
  const events = useSelector(state => eventsSelector(state));

  const { eventsDataSource, eventsStore } = useContext(EventsContext);

  useEffect(() => {
    if (isSchedulePopupOpen && isOutsideContextMenu) {
      handleClick(currentEvent);
    }
  }, [isSchedulePopupOpen]);

  const handleAllDayToggleChange = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFrom('00:00');
      setTo('00:00');
    } else {
      setFrom('');
      setTo('');
    }
  };
  const handleClick = (event) => {
    const selectedEvent = eventsDataSource.items().find(({ actionId }) => action?.id === actionId);
    if (selectedEvent) {
      const repetitive = action.repetitive;
      const { startDate, endDate, allDay, recurrence } = selectedEvent;
      startDate && setValue(dayjs(startDate));
      setChecked(allDay);
      if (startDate) {
        const dateString = new Date(startDate).toLocaleTimeString();
        const parts = dateString.split(':');
        setFrom(('0' + parts[0]).slice(-2) + ':' + parts[1]);
      }
      if (endDate) {
        const dateString = new Date(endDate).toLocaleTimeString();
        const parts = dateString.split(':');
        setTo(('0' + parts[0]).slice(-2) + ':' + parts[1]);
      }

      setIntervalType(repetitive ? "Repetitive" : "One time")

      if (repetitive) {
        setInterval(recurrence?.interval);
        const timePeriodValue = recurrence?.time_period.charAt(0).toUpperCase() + recurrence?.time_period.slice(1);
        setTimePeriod(timePeriodValue);
        const endRepeatDateValue = recurrence?.until_date ? dayjs(recurrence?.until_date) : dayjs();
        setEndRepeatDate(endRepeatDateValue);
        setEndRepeat(recurrence?.until_date ? 'Choose end date' : 'Never');
      }

    }

    setAnchorEl(currentEvent || event?.currentTarget);
    isOutsideContextMenu && handleSchedulePopupOpen();
  };


  const handleClose = () => {
    setAnchorEl(null);
    setIskeyStroke(false);
    setTimeout(() => { setIsUpdateMode(false) }, 500);
  };

  const iconRef = useRef();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleTimeChange = (event, target) => {
    if (target === 'from') {
      setFrom(event.target.value);
    } else {
      setTo(event.target.value);
    }
  };

  const updatedAction = useSelector(state => actionsSelector(state).filter(act => act.id === action.id)[0]);

  const handleSubmit = () => {
    const startDate = dateCreator(value, from);
    const endDate = dateCreator(value, to);
    if (fromCalendar || updatedAction?.scheduled) {
      // update action schedule
      const prevEvents = [...events];
      const updatedEvents = prevEvents.map((event) => {
        if (event.actionId === action.id) {
          event = {
            startDate,
            endDate,
            actionId: action.id,
            allDay: checked,
            intervalType,
            recurrence: {
              interval,
              time_period: time_period,
            },
            until: endRepeatDate ? formatDate(endRepeatDate) : '',
          };
        }
        return event;
      });
      let repetitive = false;

      const payload = generateSchedulePayload(startDate, endDate, checked);

      if (intervalType === 'Repetitive') {
        repetitive = true;
        payload.repetitive = true;
        payload.recurrence = {
          interval,
          time_period: time_period.toLowerCase(),
        };
        if (endRepeat === 'Choose end date') {
          payload.recurrence.until = formatDate(endRepeatDate);
        }
      }
      ActionAPI.modifySchedule(action.id, payload)
        .then(() => {
          dispatch(setEvents([...updatedEvents]));
          dispatch(updateAction({ ...action, scheduled: true, repetitive }));
          const { startDate, endDate } = selectedWeek;
          retrieveEvents(startDate, endDate);
        })
        .catch(() => {
          setIsOpenAlert(true);
          setMessage("Something went wrong re-scheduling the action");
          dispatch(setEvents([...prevEvents]));
        });

      eventsStore.push([{
        type: 'insert', data: {
          startDate,
          endDate,
          actionId: action.id,
          allDay: checked,
          intervalType,
          recurrence: {
            interval,
            time_period: time_period,
          },
          until: endRepeatDate ? formatDate(endRepeatDate) : '',
        }
      }]);

    } else {
      let repetitive = false;
      const payload = generateSchedulePayload(startDate, endDate, checked, repetitive);

      if (intervalType === 'Repetitive') {
        repetitive = true;
        payload.recurrence = {
          interval,
          time_period: time_period.toLowerCase(),
        };
        if (endRepeat === 'Choose end date') {
          payload.recurrence.until = formatDate(endRepeatDate);
        }
      }

      const eventId = uuid();

      dispatch(setEvents([
        ...events,
        {
          startDate,
          endDate,
          actionId: action.id,
          id: eventId,
          allDay: checked,
          intervalType,
          recurrence: {
            interval,
            time_period: time_period,
          },
        },
      ]));
      ActionAPI.createSchedule(action.id, payload)
        .then(() => {
          dispatch(updateAction({ ...action, scheduled: true, repetitive }));
          const { startDate, endDate } = selectedWeek;
          retrieveEvents(startDate, endDate);
        })
        .catch(() => {
          setIsOpenAlert(true);
          setMessage("Something went wrong when scheduling this action. Please check your selected ranges");
          dispatch(deleteEvent(eventId));
        });

      if (action.parent_goal) dispatch(deleteQueueItem(action.id));
      setIsUpdateMode(false);
      eventsStore.push([{
        type: 'insert', data: {
          startDate,
          endDate,
          actionId: action.id,
          id: eventId,
          allDay: checked,
          intervalType,
          recurrence: {
            interval,
            time_period: time_period,
          },
        },
      }]);
    }

    handleClose();
    !isOutsideContextMenu && handleParentClose();
    eventsStore.remove(action.id)
    eventsDataSource.reload();
  };

  const popoverOrigin = () => {
    if (fromCalendar) return 'right';
    if (isQueueAction) {
      if (iskeyStroke) {
        if (isOverflow) {
          return 'right';
        } else {
          return 'top';
        }
      } else if (isOverflow) {
        return 'left';
      } else {
        return 'right';
      }
    }
    return 'left';
  };

  const popoverAlign = () => {
    if (fromCalendar) return '-12px 0 0 -12px';
    if (isQueueAction) {
      if (iskeyStroke) {
        if (isOverflow) {
          return '-70px 0 0 290px';
        } else {
          return '-60px 0 0 14px';
        }
      } else if (isOverflow) {
        return '-12px 0 0 14px';
      } else {
        return '-12px 0 0 -14px';
      }
    }
    return '-12px 0 0 14px';
  };

  const handleDivClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleRemoveFromCalendar = () => {
    handleClose();
    handleParentClose();
    deleteFromCalendar(action);
  };

  return (
    <>
      {!isOutsideContextMenu && (
        <div
          aria-describedby={id}
          className={`${styles.menu} ${styles.primaryActionBackground}`}
          onClick={handleClick}
          ref={iconRef}
        >
          <div className={`${styles.menuItem} ${styles.primaryActionMenuItem}`}>
            <span className={styles.menuTextNormal}>
              {(fromCalendar || updatedAction?.scheduled) && 'Re-'}
              {constants.SCHEDULE}
            </span>
            <span className={styles.menuTextGrey}>{constants.S_KEY}</span>
          </div>
          <span className={styles.actionMenuIcon}>
            <ChevronRightOutlinedIcon style={{ fontSize: 20 }} />
          </span>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: fromCalendar || (isQueueAction && !isOverflow) ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: popoverOrigin(),
        }}
        style={{
          margin: popoverAlign(),
        }}
      >
        <Card className={styles.scheduleActionPopup}>
          <CardContent style={{ padding: "0px" }}>
            <Grid container className={styles.formRowContainer}>
              <Grid item md={12} sx={{
                '.MuiStack-root': {
                  paddingTop: "0px",
                },
              }}>
                <FormControl fullWidth className={styles.formDateContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            {(!checked || isVariant) && (
              <Grid container className={styles.formRowContainer} style={{ marginTop: "8px" }}>
                <Grid item md={3.5}>
                  <FormControl fullWidth>
                    <Select
                      id='demo-simple-select'
                      value={from}
                      onChange={(e) => handleTimeChange(e, 'from')}
                      disabled={checked}
                      className={styles.formSelectContainer}>
                      {timeSlots.map((slot, index) => {
                        return (
                          <MenuItem key={index + '-' + slot} value={slot}>
                            {slot}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3.5}>
                  <FormControl fullWidth>
                    <Select
                      id='demo-simple-select'
                      value={to}
                      onChange={(e) => handleTimeChange(e, 'to')}
                      disabled={checked}
                      className={styles.formSelectContainer}
                    >
                      {timeSlots.map((slot, index) => {
                        return (
                          <MenuItem key={index + '-' + slot} value={slot}>
                            {slot}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label='All day'
                      control={
                        <Checkbox
                          sx={{
                            color: '#0c2340',
                            padding: "4px",
                            '&.Mui-checked': {
                              color: '#0c2340',
                              fill: '#fff',
                            },
                          }}
                          checked={checked}
                          onClick={handleAllDayToggleChange}
                        />
                      }
                      sx={{
                        '.MuiFormControlLabel-label': {
                          fontFamily: 'Gellix Regular',
                          fontSize: '14px',
                        },
                        '&.MuiFormControlLabel-labelPlacementEnd': {
                          margin: "0px",
                          width: "100%"
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <div className={styles.divider}>
              <Divider className={styles.divider} />
            </div>

            <Grid container className={styles.formRowContainer}>
              <ButtonGroup
                variant='contained'
                aria-label='Select one time or repeat'
                className={styles.repeatBtnGroup}
              >
                <Button
                  onClick={() => setIntervalType('One time')}
                  className={`${styles.repetitionBtn} ${intervalType === 'One time' ? 'selected' : ''}`}

                >
                  One time
                </Button>
                <Button
                  onClick={() => setIntervalType('Repetitive')}
                  className={`${styles.repetitionBtn} ${intervalType === 'Repetitive' ? 'selected' : ''}`}

                >
                  Repetitive
                </Button>
              </ButtonGroup>
            </Grid>

            {intervalType === 'Repetitive' && (
              <>
                <Grid container className={styles.formRowContainer}>
                  <Grid item md={12}>
                    <InputLabel id='demo-simple-select-label' className={styles.repetitionInputLabel}>Repeat every</InputLabel>
                  </Grid>
                </Grid>

                <Grid container className={styles.formRowContainer}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={interval}
                        onChange={(e) => setInterval(e.target.value)}
                        className={styles.formSelectContainer}
                      >
                        {intervals().map((intervalData, index) => {
                          return (
                            <MenuItem key={index + '-' + intervalData} value={intervalData}>
                              {intervalData}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={0.3}></Grid>

                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={time_period}
                        onChange={(e) => setTimePeriod(e.target.value)}
                        className={styles.formSelectContainer}
                      >
                        {timePeriods.map((timePeriod, index) => {
                          return (
                            <MenuItem key={index + '-' + timePeriod} value={timePeriod}>
                              {timePeriod + (interval > 1 ? 's' : '')}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container className={styles.formRowContainer}>
                  <Grid item md={12}>
                    <InputLabel id='demo-simple-select-label' className={styles.repetitionInputLabel}>End repeat</InputLabel>
                  </Grid>
                </Grid>

                <Grid container className={styles.formRowContainer}>
                  <Grid item md={8}>
                    <FormControl fullWidth>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={endRepeat}
                        onChange={(e) => {
                          setEndRepeat(e.target.value);
                        }}
                        className={styles.formSelectContainer}
                      >
                        {['Never', 'Choose end date'].map((data, index) => {
                          return (
                            <MenuItem key={index + '-' + data} value={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {endRepeat === 'Choose end date' && (
                  <Grid container className={styles.formRowContainer}>
                    <Grid item md={8}>
                      <DatePicker
                        defaultValue={endRepeatDate}
                        value={endRepeatDate}
                        onChange={(value) => setEndRepeatDate(value)}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {/* <div className={styles.divider}>
              <Divider className={styles.divider} />
            </div> */}
          </CardContent>
          <CardActions style={{ padding: "0px" }}>
            <Grid container>
              <div className={styles.buttonsWrapperFlexDiv}>
                <Button size='small' className={styles.scheduleSaveBtn} onClick={handleSubmit}>
                  Save
                </Button>
                {isUpdateMode && (
                  <Button
                    size='small'
                    className={styles.scheduleRemoveBtn}
                    onClick={handleRemoveFromCalendar}
                  >
                    Remove from calendar
                  </Button>
                )}
              </div>
            </Grid>

          </CardActions>
          {/* {isUpdateMode && (
            <CardActions>

            </CardActions>
          )} */}
        </Card>
      </Popover>
    </>
  );
}
