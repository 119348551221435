import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import Scheduler, { AppointmentDragging } from 'devextreme-react/scheduler';
import { Grid } from '@mui/material';
import { Queue } from '../components/queue';
import { Header } from '../components/header';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../contexts/appContext';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import FilterContainer from '../components/scheduler/FilterContainer';
import CircleIcon from '@mui/icons-material/Circle';
import ActionContextMenu from '../components/nodeTypes/contextMenu/ActionContextMenu';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import './calendar.css';
import { views } from '../constants/scheduler';
import styles from './styles.module.scss';
import { ActionAPI } from '../services/actions/actionService';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { generateSchedulePayload, convertUTCDateToLocalDate } from '../utils/utils';
import { QueueAPI } from '../services/queue/queueService';
import DeleteQueueDialog from '../components/deleteDialogs/DeleteQueueDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CustomCalendar from '../components/customCalendar/CustomCalendar';
import { startOfWeek, lastDayOfWeek } from 'date-fns';
import { setQueueItems, deleteQueueItem } from '../components/queue/state/queueSlice';
import { getWeek, format } from 'date-fns';
import { uuid } from '../utils/utils';
import { updateAction, toggleAchievedAction, deleteAction } from '../redux/models/action';
import { actionsSelector } from '../redux/selectors';
import { setEvents } from '../redux/models/event';
import useForceUpdate from '../hooks/plantree/useForceUpdate';
import { Draggable } from 'devextreme-react';
import { EventsContext } from '../contexts/eventsContext';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import ConfirmationPopup from '../components/confirmationPopup/ConfirmationPopup';
import cloneDeep from 'lodash/cloneDeep';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CustomScheduler = React.memo(({ onAppointmentAdd, onAppointmentRemove, dragStart, dragEnd, ...props }) => {
  return (
    <Scheduler
      {...props}
      style={{ height: 'calc(100vh - 250px)' }}
    >
      <AppointmentDragging
        group="calendarLayoutGroup"
        onRemove={onAppointmentRemove}
        onAdd={onAppointmentAdd}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
      >
      </AppointmentDragging>
    </Scheduler>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.currentDate === nextProps.currentDate &&
    prevProps.className === nextProps.className
  );
});


CustomScheduler.displayName = 'MyComponent';

function CalendarLayout() {
  const proceedWithUpdatingEventRef = useRef(null);
  const eventsBeforeEventUpdateRef = useRef(null);
  var dragEntered = useRef(false);
  const [scheduleToSchedule, setScheduleToSchedule] = useState();
  const { eventsStore, eventsDataSource, eventsRef } = useContext(EventsContext);
  const { color } = useSelector((state) => state.currentGoalColor);
  const [itemOverId, setItemOverId] = useState('');
  const [isQueuedItemDragged, setIsQueuedItemDragged] = useState(false);
  const [currentView, setCurrentView] = useState('day');
  const [editActionId, setEditActionId] = useState('');
  const [selectedAction, setSelectedAction] = useState({});
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState({
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 1 }),
  });

  const hasMoved = useRef(false);

  const dispatch = useDispatch();
  const { queueItems } = useSelector((state) => state.queueItems);
  const actions = useSelector(state => actionsSelector(state)) || [];
  let itemValue = useRef('');
  let itemRef = useRef();
  let draggedAction = {};
  const cActionRef = useRef([]);
  const forceUpdate = useForceUpdate();
  const [draggedActionForReordering, setDraggedActionForReordering] = useState();
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
  const [isOpenDeleteRepetitiveActionFromCalendarPopup, setIsOpenDeleteRepetitiveActionFromCalendarPopup] = useState(false);
  const [isOpenToggleRepetitiveActionCompletedConfirmationPopup, setIsOpenToggleRepetitiveActionCompletedConfirmationPopup] = useState(false);

  const [apmntToBeToggled, setApmntToBeToggled] = useState();
  const [actionIdToDelete, setActionIdToDelete] = useState(null);

  const draggedActionForReorderingRef = useRef(draggedActionForReordering);
  draggedActionForReorderingRef.current = draggedActionForReordering;
  useEffect(() => {
    draggedActionForReorderingRef.current = draggedActionForReordering;
  }, [draggedActionForReordering])

  const actionsRef = useRef(actions);
  actionsRef.current = actions;
  useEffect(() => {
    actionsRef.current = actions;
  }, [actions]);


  const queueItemsRef = useRef(queueItems);
  queueItemsRef.current = queueItems;
  useEffect(() => {
    queueItemsRef.current = queueItems;
  }, [queueItems]);

  const retrieveEvents = useCallback(
    (startDate, endDate) => {
      const startDt = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(
        -2,
      )}-${('0' + startDate.getDate()).slice(-2)}`;
      const endDt = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${(
        '0' + endDate.getDate()
      ).slice(-2)}`;
      ActionAPI.getEvents(startDt, endDt).then((result) => {
        const results = result.map((data) => {
          let item = {};
          item.startDate = convertUTCDateToLocalDate(new Date(data.start_dt));
          item.endDate = convertUTCDateToLocalDate(new Date(data.end_dt));
          item.allDay = data.is_anytime;
          item.id = uuid();
          item.name = data.name;
          item.color = data.color
          item.actionId = data.id || uuid();
          item.recurrence = data.recurrence;

          return item;
        });
        dispatch(setEvents(results));
      }).catch((error) => {
        setMessage('Failed to retrieve events. Please refresh the page and try again.');
        setIsOpenAlert(true);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    let startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(new Date(), { weekStartsOn: 1 });

    retrieveEvents(startDate, endDate);
  }, [retrieveEvents]);

  const dragStart = (e, queuedItemTxt) => {
    setIsQueuedItemDragged(queuedItemTxt ? true : false);
    eventsBeforeEventUpdateRef.current = cloneDeep(eventsDataSource.items());

    if (e.fromData) {
      const actionId = e.fromData.id
      const action = actionsRef.current.find((a) => a.id === actionId);
      if (action) {
        setDraggedActionForReordering(action);
        draggedAction = action;
      }
    }

    if (e.itemData) {
      const actionId = e.itemData.actionId
      const action = actionsRef.current.find((a) => a.id === actionId);
      if (action) {
        setDraggedActionForReordering(action);
        draggedAction = action;
      }
    }
  };

  const deleteFromCalendar = (actionId) => {
    const action = actionsRef.current.find((a) => a.id === actionId);
    if (action.repetitive && !isOpenDeleteRepetitiveActionFromCalendarPopup) {
      setIsOpenDeleteRepetitiveActionFromCalendarPopup(true);
      setActionIdToDelete(actionId);
    } else {
      const prevEvents = [...eventsDataSource.items()];
      const updatedEvents = prevEvents.filter((event) => event.actionId !== actionId);
      dispatch(setEvents(updatedEvents));
      ActionAPI.deleteFromCalendar(actionId).catch((error) => {
        setMessage('Something went wrong. Please try again');
        setIsOpenAlert(true);
        dispatch(setEvents([...prevEvents]));
      });
      setIsOpenDeleteRepetitiveActionFromCalendarPopup(false);
    }
  };

  // Handler for the back button from popup
  const handleBackDeleteFromCalendarAction = () => {
    setIsOpenDeleteRepetitiveActionFromCalendarPopup(false);
  };

  // Handler for the submit button from popup
  const handleSubmitDeleteFromCalendarAction = () => {
    deleteFromCalendar(actionIdToDelete);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnd = (e) => {
    let scheduleToScheduleLocal
    if (e.toData === undefined) {
      setScheduleToSchedule(true);
      scheduleToScheduleLocal = true;
    } else if (e.toData) {
      setScheduleToSchedule(false);
      scheduleToScheduleLocal = false;
    }

    if (draggedActionForReordering) {
      let position
      position = queueItemsRef.current.indexOf(draggedActionForReordering?.id);

      if (position === undefined || position === null || position === -1) {
        position = queueItems?.length;
      }
      console.dir(e)
      if (isQueuedItemDragged && e.toComponent.NAME !== "dxScheduler" && e.toComponent.NAME !== undefined) {
        const debouncedModifyQueue = debounce(() => {
          QueueAPI.modify({
            actionId: draggedActionForReordering?.id,
            new_position: position
          })
            .catch((error) => {
              if (
                error.response.data.error !== "New position cannot be greater than the number of items in the queue" &&
                error.response.data.error !== "Action id provided is not in the queue"
              ) {
                setMessage('Something went wrong. Please try again');
                setIsOpenAlert(true);
              }
            });
        }, 500);

        debouncedModifyQueue();

      }

    } else if (e.itemData) {
      if (dragEntered.current) {
        onAppointmentRemove(e);
      }
    }
    setDraggedActionForReordering(null);
    dragEntered.current = false;
    setTimeout(() => { hasMoved.current = false }, 0);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    if (e.target.id === "queue-area" && e.relatedTarget?.getAttribute('data-component') !== "queueItem") {
      let newQueue = [...queueItems];
      let existingIndex = newQueue.indexOf(draggedAction.id);
      if (existingIndex !== -1) {
        newQueue.splice(existingIndex, 1);
      }
      dispatch(setQueueItems(newQueue));
    }
  };

  const dragEnter = (e, id, index) => {
    dragEntered.current = true;
    if (draggedActionForReordering?.id) {
      let newQueue = [...queueItemsRef.current];
      let existingIndex = newQueue.indexOf(draggedActionForReordering.id);
      if (id) {
        if (id !== draggedActionForReordering.id) {
          if (existingIndex !== -1) {
            newQueue.splice(existingIndex, 1);
          }
          newQueue.splice(index, 0, draggedActionForReordering.id);
          dispatch(setQueueItems(newQueue));
        }
      }
      else {
        if (existingIndex !== -1) {
          newQueue.splice(existingIndex, 1);
        }
        newQueue.push(draggedActionForReordering.id);
        dispatch(setQueueItems(newQueue));
      }
    };
  };

  const selectCurrentView = (view) => {
    setCurrentView(view);
  };

  const handleInputChange = (e) => {
    itemValue.current = e.target.value;
    e.stopPropagation();
  };
  const handleInputClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEditAction = ({ id, name }) => {
    setEditActionId(id);
    itemValue.current = name;
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSaveAction();
      setEditActionId('');
    }
  };

  const handleSaveAction = (note, id) => {
    let actionValue = {};
    if (note) {
      actionValue = note;
    } else {
      actionValue['name'] = itemValue.current;
    }

    ActionAPI.modify(editActionId || id, actionValue).then((response) => {
      if (response) {
        dispatch(updateAction(response)).catch((error) => {
          setMessage('Saving action failed. Please check your input and try again.');
          setIsOpenAlert(true);
        });
      }
    });
    forceUpdate();
  };

  const handleSaveNote = (note, action) => {
    const actionValue = { notes: note };
    handleSaveAction(actionValue, action.id);
  };

  const handleFocus = (event) => event.target.select();

  const handleToggleAchievedAction = (action) => {
    dispatch(toggleAchievedAction(action.id));
    ActionAPI.modify(action.id, { completed: !action.completed }).catch((error) => {
      setMessage('Failed to toggle action status. Please try again.');
      setIsOpenAlert(true);
      dispatch(toggleAchievedAction(action.id));
    });
  };

  const handleProceedWithTogglingRepetitiveActionCompleted = () => {
    const apmnt = apmntToBeToggled;

    const { actionId } = apmnt;
    const prevAction = actionsRef.current.find(action => action.id === actionId);
    const updatedAction = { ...prevAction, completed: !prevAction.completed };

    dispatch(updateAction(updatedAction));

    ActionAPI.modify(actionId, { completed: !prevAction.completed })
      .then()
      .catch((error) => {
        setMessage('Failed to toggle action status. Please try again.');
        setIsOpenAlert(true);
        dispatch(updateAction(prevAction));
      });
    setIsOpenToggleRepetitiveActionCompletedConfirmationPopup(false);
  };

  const toggleCompleteAction = (apmnt) => {
    setApmntToBeToggled(apmnt);
    setIsOpenToggleRepetitiveActionCompletedConfirmationPopup(true);
  };

  const handleDenyTogglingRepetitiveActionCompleted = () => {
    setIsOpenToggleRepetitiveActionCompletedConfirmationPopup(false);
  };

  const handleMoveToQueue = (action, position = null) => {
    if (!hasMoved.current) {
      dispatch(updateAction({ ...action, scheduled: false }));
      const actionIds = [action.id];

      position = queueItemsRef.current.indexOf(draggedActionForReorderingRef.current?.id);

      if (position === undefined || position === null || position === -1) {
        position = queueItems?.length;
      }

      saveQueueAction(position, actionIds, action);
      eventsStore.remove(action.id);
      eventsDataSource.reload()
      hasMoved.current = true;
    }
  };

  const saveQueueAction = (position, actionIds, action) => {
    let queueArray = [...queueItemsRef.current];
    let previousIndex = queueArray.indexOf(action.id);

    if (previousIndex !== -1) {
      queueArray.splice(previousIndex, 1);

      if (previousIndex < position) {
        position--;
      }
    }

    queueArray.splice(position, 0, action.id);

    dispatch(setQueueItems(queueArray));
    const prevEvents = [...eventsDataSource.items()];
    const updatedEvents = prevEvents.filter((event) => {
      return event.actionId !== action.id;
    });
    dispatch(setEvents(updatedEvents));

    QueueAPI.create(position, actionIds).catch((error) => {
      setMessage('Failed to save queue action. Please try again.');
      setIsOpenAlert(true);
      dispatch(setEvents([...prevEvents]));
    });

    setDraggedActionForReordering(null);
  };

  const deleteActionCompletely = (deleteActionId) => {
    eventsStore.remove(deleteActionId);
    eventsDataSource.reload()
    dispatch(deleteAction(deleteActionId));
    deleteFromCalendar(deleteActionId);
    ActionAPI.delete(deleteActionId).then(() => { }).catch((error) => {
      setMessage('Something went wrong. Please try again');
      setIsOpenAlert(true);
    });
  };

  const deleteActionFromCalendar = (action) => {
    const actionToDelete = actionsRef.current.find(act => act.id === action.id);

    if (actionToDelete.repetitive && !isOpenDeleteRepetitiveActionFromCalendarPopup) {
      setIsOpenDeleteRepetitiveActionFromCalendarPopup(true);
      setActionIdToDelete(action.id);
    } else {
      eventsStore.remove(action.id);
      eventsDataSource.reload();

      const prevEvents = [...eventsDataSource.items()];
      const updatedEvents = prevEvents.filter((event) => event.actionId !== action.id);

      dispatch(setEvents(updatedEvents));
      dispatch(updateAction({ ...actionToDelete, scheduled: false }));
      ActionAPI.deleteFromCalendar(action.id).catch((error) => {
        setIsOpenAlert(true);
        setMessage('There was a problem deleting this action from the calendar');
        dispatch(setEvents(prevEvents));
      });
      setIsOpenDeleteRepetitiveActionFromCalendarPopup(false);
    }
  };

  const handleDragOver = (e) => {
    e.target.style.background = '#E2ECF1';
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.target.style.background = 'none';
  };

  const DataCellComponent = (props) => {
    return (
      <div
        id="customDataCellDiv"
        className={styles.customDataCellDiv}
        // style={{ width: '100%', height: '100%', pointerEvents: "all", background: "" }}
        // onDrop={(e) => handleOnDrop(e, props.itemData)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      // onClick={(e) => { }}
      // onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); e.cancel = true; return false; }}
      // onMouseOver={(e) => { e.preventDefault(); e.stopPropagation(); e.cancel = true; return false; }}
      // onMouseEnter={(e) => { e.preventDefault(); e.stopPropagation(); e.cancel = true; return false; }}
      // onMouseLeave={(e) => { e.preventDefault(); e.stopPropagation(); e.cancel = true; return false; }}
      // onTouchStart={(e) => { e.preventDefault(); e.cancel = true; return false; }}
      // onTouchMove={(e) => { e.preventDefault(); e.cancel = true; return false; }}
      >
        {props.children}
      </div>
    );
  };

  const renderDataCellComponent = (data) => {
    return <DataCellComponent itemData={data} />;
  };


  const EventComponent = (props) => {
    const { actionId, startDate, endDate } = props.data.appointmentData;
    const action = useSelector((state) => actionsSelector(state, actionId));

    useEffect(() => {
      forceUpdate();
    }, [action?.completed]);

    const duration = moment.duration(moment(endDate).diff(moment(startDate))).asMinutes();

    let parent_goal = null;
    let notes = null;
    let completed = null;
    let name = null;
    let colorToRender = null;
    let repetitive = null;
    let recurrence = null;

    if (action) { // Wayy event
      parent_goal = action.parent_goal;
      notes = action.notes;
      completed = action.completed;
      name = action.name;
      colorToRender = action.color ? action.color : color;
      repetitive = action.repetitive;
    } else { // External calendar event
      name = props.data.appointmentData.name;
      colorToRender = props.data.appointmentData.color;
    }

    return (
      <div
        className={
          'eventContainer ' +
          (!action ? 'externalEvent' :
            (completed ? 'completedEvent' : ''))
        }

        data-start={JSON.stringify({
          goalId: parent_goal,
          id: actionId,
          name: name,
          notes: notes,
          completed: completed,
          fromCalendar: true,
          duration: duration,
        })}
      >
        {editActionId === actionId && action ? (
          <TextField
            className={`${styles.calendarInputControl} ${completed ? styles.calendarInputControlCompleted : ''
              }`}
            defaultValue={name}
            onChange={(e) => handleInputChange(e, props.index)}
            onClick={handleInputClick}
            onKeyDown={(e) => handleKeyDown(e)}
            onBlur={() => handleSaveAction()}
            ref={(el) => (cActionRef.current[props.index] = el)}
            id={'calendarAction' + actionId}
            autoFocus={editActionId === actionId}
            onFocus={handleFocus}
            sx={{ width: '100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  {completed ? (
                    <CheckCircleOutlineIcon
                      sx={{ color: colorToRender, fontSize: '20px' }}
                      style={{ marginTop: '-1px', marginLeft: '-15px' }}
                    />
                  ) : (
                    <CircleIcon
                      sx={{ color: colorToRender, fontSize: '20px' }}
                      onClick={() => toggleCompleteAction(props.data.appointmentData)}
                      style={{ cursor: 'pointer', marginTop: '-1px', marginLeft: '-12px' }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        ) : (
          <>

            <Grid container>
              <Grid
                item
                md={11}
                className={styles.eventText}
                style={currentView === 'week' ? { paddingLeft: '0px', fontSize: '10px', width: "100%" } : {}}
              >
                <Grid className={styles.eventIcon}>
                  {completed ? (
                    <CheckCircleOutlineIcon
                      sx={{ color: colorToRender, fontSize: '20px' }}
                      onClick={() => toggleCompleteAction(props.data.appointmentData)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <CircleIcon
                      sx={{ color: colorToRender, fontSize: '20px' }}
                      onClick={() => toggleCompleteAction(props.data.appointmentData)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </Grid>
                <div className={styles.eventComponentText}>
                  {name}
                  {!action && (
                    <Grid className={styles.externalCalendarEventSignifier} style={{ fontSize: "7.5px", lineHeight: "1", width: "30%" }}>
                      External event
                    </Grid>
                  )}
                </div>
              </Grid>

              {action && (
                <Grid item md={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className='actionContextMenuInCalendar'>
                    <ActionContextMenu
                      actionObj={{
                        id: actionId,
                        name: name,
                        parent_goal: parent_goal,
                        notes: notes,
                        completed: completed,
                        color: colorToRender,
                        repetitive: repetitive,
                      }}
                      editAction={handleEditAction}
                      deleteAction={setSelectedAction}
                      duplicateAction={null}
                      moveToQueueAction={() =>
                        handleMoveToQueue({
                          goalId: parent_goal,
                          id: actionId,
                          name: name,
                          completed,
                        })
                      }
                      toggleAchievedAction={handleToggleAchievedAction}
                      handleRemoveFromQueue={() => { }}
                      handleSaveNote={handleSaveNote}
                      deleteActionFromCalendar={deleteActionFromCalendar}
                      editActionId={editActionId}
                      moreOption={''}
                      showEventIcon={false}
                      alwaysShow={true}
                      fromCalendar={true}
                      setEvents={setEvents}
                    />

                  </div>
                </Grid>
              )}
            </Grid>

          </>
        )}
      </div>
    );
  };


  const handleUpdateEvent = useCallback((data) => {
    const proceedWithUpdatingEvent = () => {
      try {
        const updatedEvents = eventsDataSource.items().map(event => {
          if (event.actionId === updatedEvent.actionId) {
            const originalStartDate = dayjs(event.startDate);
            const originalEndDate = dayjs(event.endDate);
            const newStartDate = dayjs(updatedEvent.startDate);
            const newEndDate = dayjs(updatedEvent.endDate);

            const replacedStartDate = originalStartDate.hour(newStartDate.hour()).minute(newStartDate.minute());
            const replacedEndDate = originalEndDate.hour(newEndDate.hour()).minute(newEndDate.minute());

            return { ...event, startDate: replacedStartDate, endDate: replacedEndDate };
          }
          return event;
        });
        dispatch(setEvents(updatedEvents));
      }
      catch (error) {
        setMessage('Something went wrong. Please try again');
        setIsOpenAlert(true);
      }
      const payload = generateSchedulePayload(
        updatedEvent.startDate,
        updatedEvent.endDate,
        updatedEvent.allDay,
        repetitive,
      );

      if (repetitive) {
        const interval = recurrence.interval;
        const time_period = recurrence.time_period;
        payload.recurrence = {
          interval,
          time_period: time_period.toLowerCase(),
        };
      }
      ActionAPI.modifyScheduleDebounced(updatedEvent.actionId, payload);
    }

    const updatedEvent = { ...data.appointmentData };
    let repetitive = false;
    let recurrence;
    if (updatedEvent.recurrence) {
      repetitive = true;
      recurrence = updatedEvent.recurrence;
    }

    if (repetitive) {
      proceedWithUpdatingEventRef.current = proceedWithUpdatingEvent;
      setIsOpenConfirmationPopup(true);
    }
    else {
      proceedWithUpdatingEvent();
    }
  });

  const handleConfirmUpdateEvent = () => {
    setIsOpenConfirmationPopup(false);
    if (proceedWithUpdatingEventRef.current) {
      proceedWithUpdatingEventRef.current();
      proceedWithUpdatingEventRef.current = null;
    }
  };

  const handleDenyUpdateEvent = () => {
    console.dir(eventsBeforeEventUpdateRef.curent)
    setIsOpenConfirmationPopup(false);
    dispatch(setEvents(eventsBeforeEventUpdateRef.current));
  }

  const handleDateChange = (data) => {
    var curr = new Date(data); // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    retrieveEvents(firstday, lastday);
  };

  const onEventFormOpening = (e) => {
    e.cancel = true;
    return false;
  };

  const handleSelectDateFromCustomCalendar = (date) => {
    setCurrentDate(date);
  };

  const handleWeekChangeFromCustomCalendar = (range) => {
    const { startDate, endDate } = range;
    setCurrentDate(startDate);
    retrieveEvents(startDate, endDate);
  }

  document.body.addEventListener('pointerdown', function (event) {
    if (event.target.matches('.dx-scheduler-date-table-cell') || event.target.matches('.dx-scheduler-cell-sizes-vertical') || event.target.matches('#customDataCellDiv') || event.target.closest('tbody')) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, true);

  document.body.addEventListener('mouseover', function (event) {
    if (event.target.matches('.dx-scheduler-date-table-cell') || event.target.matches('.dx-scheduler-cell-sizes-vertical') || event.target.matches('#customDataCellDiv') || event.target.closest('tbody')) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, true);

  const customizeDateNavigatorText = (e) => {
    return `${format(e.startDate, 'MMMM')} Week  ${getWeek(e.startDate)}`;
  };

  const handleAppointmentRendered = (ea) => {
    // Check if ea.appointmentElement exists and is a DOM element
    if (!ea.appointmentElement || !(ea.appointmentElement instanceof Element)) {
      return; // exit function if not a valid element
    }

    // Keydown listener
    ea.appointmentElement.addEventListener("keydown", (e) => {
      if (e.key === "a") {
        const selectedEventAction = actionsRef.current.find((a) => a.id === ea.appointmentData.actionId);
        handleToggleAchievedAction(selectedEventAction);
      }
    });

    ea.appointmentElement.addEventListener('dragover', function (e) {
      e.preventDefault();
    });

  }

  const onAppointmentRemove = (e, position = null) => {
    let action
    if (e.itemData) {
      action = actionsRef.current.find((a) => a.id === e.itemData.actionId)
    }

    if (action.repetitive) {  // Check if action is repetitive
      e.cancel = true;
      setMessage("A single instance of a repetitive action can't be moved into the queue. Please use the action context menu to move all instances.");
      setIsOpenAlert(true);
    } else {
      if (!action.completed) {
        handleMoveToQueue(action);
      } else {
        e.cancel = true;
        setMessage("Completed action can't be moved to queue");
        setIsOpenAlert(true);
      }
    }
  }

  const onAppointmentAdd = (e) => {
    const data = e.fromData;
    const { id, scheduled } = data;
    var { startDate, endDate, allDay } = e.itemData;
    if (allDay === null || allDay === undefined) {
      allDay = false;
    }
    if (!scheduled) {

      if (!endDate || endDate.getTime() === startDate.getTime()) {
        endDate = new Date(startDate.getTime() + 30 * 60 * 1000);  // Add 1 hour (60 mins * 60 secs * 1000 ms)
      }

      const eventId = uuid();
      const newEvent = {
        startDate,
        endDate,
        actionId: id,
        id: eventId,
        allDay,
      };

      dispatch(setEvents([...eventsDataSource.items(), newEvent]));
      dispatch(deleteQueueItem(id));
      dispatch(updateAction({ ...data, scheduled: true }));
      ActionAPI.createSchedule(id, generateSchedulePayload(startDate, endDate, allDay))
        .catch(({ response }, error) => {
          if (response?.status === 409) {
            setMessage('This action is already in the calendar');
          } else {
            setMessage('There was a problem adding this action to the calendar');
          }
          setIsOpenAlert(true);
          dispatch(setEvents([...eventsDataSource.items().filter((event) => event.id !== eventId)]));
        });

      eventsStore.push([{ type: 'insert', data: newEvent }]);
      eventsDataSource.reload()
    } else {
      e.cancel = true;
      return false;
    }

  };


  return (
    <AppContext.Provider
      value={{
        handleMoveToQueue,
        dragStart,
        dragEnter,
        dragOver,
        dragEnd,
        dragLeave,
        itemRef,
        itemOverId,
        setIsOpenAlert,
        setMessage,
        selectedWeek,
        setSelectedWeek,
        retrieveEvents,
        dragItem: draggedAction.id,
        currentDraggedActionId: draggedAction.id,
        eventsDataSource,
        eventsStore,
        draggedActionForReordering,
        hasMoved,
        dragEntered,
      }}
    >
      <>
        <Grid container>
          <Grid item md={currentView === 'day' ? 8 : 6} style={{ background: '#E2ECF1' }}>
            <Header currentView={currentView} />
            <div className='app-container'>
              <Outlet />
            </div>
          </Grid>
          <Grid item md={currentView === 'day' ? 4 : 6}>
            <Grid container>
              <Grid item md={12} className={'filter-container'}>
                <FilterContainer selectCurrentView={selectCurrentView} currentView={currentView} />
              </Grid>
              <Grid item md={12}>
                {
                  currentView === "day" &&
                  <CustomCalendar
                    onSelectDate={handleSelectDateFromCustomCalendar}
                    onWeekChange={handleWeekChangeFromCustomCalendar}
                    currentView={currentView}
                  />
                }

                <CustomScheduler
                  groups={['id']}
                  id='scheduler'
                  dataSource={eventsDataSource}
                  // keyExpr='id'
                  views={views}
                  defaultCurrentView='day'
                  defaultCurrentDate={currentDate}
                  currentDate={currentDate}
                  startDayHour={6}
                  useDropDownViewSwitcher={false}
                  showCurrentTimeIndicator={false}
                  currentView={currentView}
                  firstDayOfWeek={1}
                  appointmentComponent={(props) => <EventComponent {...props} />}
                  dataCellRender={renderDataCellComponent}
                  editing={true}
                  onAppointmentClick={(e) => {
                    e.cancel = true;
                  }}
                  onAppointmentRendered={handleAppointmentRendered}
                  onAppointmentUpdated={handleUpdateEvent}
                  onAppointmentFormOpening={onEventFormOpening}
                  onCurrentDateChange={handleDateChange}
                  className={currentView === 'day' ? 'dayView' : 'weekView'}
                  customizeDateNavigatorText={customizeDateNavigatorText}
                  onAppointmentAdd={onAppointmentAdd}
                  onAppointmentRemove={onAppointmentRemove}
                  allDayPanelMode="allday"
                  dragStart={dragStart}
                  dragEnd={dragEnd}
                >
                </CustomScheduler>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ position: "fixed", bottom: '0', left: '0', right: '0' }}>
          <Draggable
            id="list"
            data="dropArea"
            group="calendarLayoutGroup"
            onDragStart={(e) => { e.cancel = true; }}

          >
            <Queue actions={actions} id="queue" />
          </Draggable>
        </div>

        <DeleteQueueDialog
          isRepetitive={selectedAction.repetitive}
          deleteActionId={selectedAction.id}
          actionName={selectedAction.name}
          deleteAction={deleteActionCompletely}
          deleteCalendarAction={deleteFromCalendar}
          isCalendarAction={true}
          setDeleteActionId={() => { }}
          deleteFromQueue={() => { }}
        />
        <ConfirmationPopup
          heading={'Reschedule Repetitive Event'}
          subHeading={`Are you sure you want to reschedule all instances of this repetitive event?`}
          submitButtonText={'Reschedule'}
          isOpen={isOpenConfirmationPopup}
          backAction={handleDenyUpdateEvent}
          submitAction={() => {
            setIsOpenConfirmationPopup(false);
            handleConfirmUpdateEvent();
          }}
        />
        <ConfirmationPopup
          heading={'Delete Repetitive Action from Calendar'}
          subHeading={`Are you sure you want to delete this repetitive action from calendar? This will also remove all repetitions from the calendar.`}
          submitButtonText={'Delete from Calendar'}
          isOpen={isOpenDeleteRepetitiveActionFromCalendarPopup}
          backAction={handleBackDeleteFromCalendarAction}
          submitAction={handleSubmitDeleteFromCalendarAction}
        />
        <ConfirmationPopup
          heading={'Toggling a repetitive Action "(un)completed" will toggle all of its instances.'}
          subHeading={`Currently toggling a repetitive action "completed" will toggle of its instances as "(un)completed". We are working to add the option to only toggle one instance. Do you wish to proceed?`}
          submitButtonText={'Toggle all instances of the action "(un)completed"'}
          isOpen={isOpenToggleRepetitiveActionCompletedConfirmationPopup}
          backAction={handleDenyTogglingRepetitiveActionCompleted}
          submitAction={handleProceedWithTogglingRepetitiveActionCompleted}
        />

      </>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        message={message}
        key={'top center'}
        autoHideDuration={6000}
      >
        <Alert severity='error'>{message}</Alert>
      </Snackbar>
    </AppContext.Provider >
  );
}

export default CalendarLayout;
