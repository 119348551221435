import React, { useState, useContext } from 'react';
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from 'date-fns';
import styles from './styles.module.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { AppContext } from '../../contexts/appContext';

const CustomCalendar = ({ onSelectDate, onWeekChange, currentView }) => {
  const { setSelectedWeek } = useContext(AppContext);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChangeWeek = (btnType) => {
    if (btnType === 'prev') {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));

      let startDate = startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 });
      const endDate = lastDayOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 });
      onWeekChange({ startDate, endDate });
      setSelectedDate(startDate);
      setSelectedWeek({ startDate, endDate });
    }
    if (btnType === 'next') {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));

      let startDate = startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 });
      const endDate = lastDayOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 });
      onWeekChange({ startDate, endDate });
      setSelectedDate(startDate);
      setSelectedWeek({ startDate, endDate });
    }
  };

  const onDateClickHandle = (day) => {
    setSelectedDate(day);
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    onSelectDate(day, { startDate, endDate });
  };

  const renderHeader = () => {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.prevIcon} onClick={() => handleChangeWeek('prev')}>
          <NavigateBeforeIcon fontSize='medium' />
        </div>
        <div className={styles.weekText}>{`${format(
          currentMonth,
          'MMMM',
        )} Week  ${currentWeek}`}</div>
        <div className={styles.nextIcon} onClick={() => handleChangeWeek('next')}>
          <NavigateNextIcon fontSize='medium' />
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = 'EEE';
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormatDay = 'd';
    let day = startDate;
    let formattedDate = '';
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormatDay);
        const cloneDay = day;
        days.push(
          <div className={`${styles.dayCell}`} key={i}>
            <span
              className={`${styles.dayName} ${isSameDay(day, selectedDate) ? styles.calendarDaySelected : ''
                }`}
            >
              {format(addDays(startDate, i), dateFormat)}
            </span>
            <div
              className={`${styles.dayNumber} ${isSameDay(day, selectedDate) ? styles.calendarDayTodaySelected : ''
                }`}
              key={day}
              onClick={() => {
                const dayStr = format(cloneDay, 'ccc dd MMM yy');
                onDateClickHandle(cloneDay, dayStr);
              }}
            >
              <span className={`${styles.dayNumberText} dayNumberText`}>{formattedDate}</span>
            </div>
          </div>,
        );
        day = addDays(day, 1);
      }
    }
    return <div className={styles.weekContainer}>{days}</div>;
  };

  return (
    <div
      className={
        styles.customCalendarContainer +
        ' ' +
        (currentView === 'week' ? styles.customCalendarContainerForWeek : '')
      }
    >
      {renderHeader()}
      {renderDays()}
    </div>
  );
};

export default CustomCalendar;
