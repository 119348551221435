import axios from 'axios';
import Cookies from 'js-cookie';

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  const clerkAccessToken = Cookies.get('__session');
  return clerkAccessToken;
}

const baseUrl = process.env.REACT_APP_API_URL;

export const api = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

export const authApi = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

// defining a custom error handler for all APIs
const authErrorHandler = (error) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
authApi.interceptors.response.use(undefined, (error) => {
  return authErrorHandler(error);
});
