import styles from './styles.module.scss';
import React, { memo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { constants } from './constants';
const DeleteQueueDialog = ({
  deleteActionId,
  actionName,
  setDeleteActionId,
  deleteAction,
  deleteFromQueue,
  isCalendarAction,
  deleteCalendarAction,
  isRepetitive,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (deleteActionId) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [deleteActionId]);

  const handleClose = () => {
    setDialogOpen(false);
    setDeleteActionId('');
  };

  const handleDeleteAction = () => {
    setDialogOpen(false);
    setDeleteActionId('');
    deleteAction(deleteActionId);
  };

  const handleDeleteFromQueue = () => {
    setDialogOpen(false);
    if (isCalendarAction) {
      deleteCalendarAction(deleteActionId);
    } else {
      setDeleteActionId('');
      deleteFromQueue();
    }
  };

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className={styles.title}>
          {constants.DELETE} <b>{actionName}</b>
          {isRepetitive ? constants.REPETITIVE_ACTION_HEADING : constants.ACTION_HEADING}
        </DialogTitle>

        <DialogActions className={styles.queueContainer}>
          <Button className={styles.goBack} onClick={handleClose}>
            {constants.GO_BACK}
          </Button>
          <Button
            className={styles.goBack + ' ' + styles.fromQueue}
            onClick={handleDeleteFromQueue}
          >
            {constants.KEEP_IN_PLAN_TREE}
          </Button>
          {isRepetitive ? (
            <Button className={styles.delete} onClick={handleDeleteAction}>
              {constants.DELETE_ALL_INSTANCES}
            </Button>
          ) : (
            <Button className={styles.delete} onClick={handleDeleteAction}>
              {constants.DELETE_COMPLETELY}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(DeleteQueueDialog);
