import SubGoalEdge from './SubGoalEdge';
import EnterActionEdge from './EnterActionEdge';
import TabActionEdge from './TabActionEdge';
import GoalEdge from './GoalEdge';

export const edgeTypes = {
  workflow: SubGoalEdge,
  enterAction: EnterActionEdge,
  tabAction: TabActionEdge,
  goal: GoalEdge,
};

export default edgeTypes;
